import React from 'react';
import './sectioncourses.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import imgCursosPresenciais from '../../../assets/img/cursos-destaque/cursos-presenciais.webp';
import imgCursosOnline from '../../../assets/img/cursos-destaque/cursos-online.webp';
import { ModalInvestimento } from '../../';

export default function SectionCourses() {
	return (
		<section className="album bg-light">
			<div className="container">
				<div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-4 py-4">
					<div className="col">
						<div className="card shadow-sm">
							<img src={imgCursosPresenciais} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
							<div className="card-body">
								<p className="display-6"><b>Cursos Presenciais</b></p>
								<p className="card-text mt-4">
									O curso é realizado
									<strong> nas salas de aula da Konfisio </strong>
									de forma presencial.
									<strong> Apenas a matrícula é feita online.</strong>
								</p>
								<div className="d-flex align-items-center">
									<HashLink to="/matricula-online/#formmatricula" className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1">Matrícula Online</HashLink>
									<button
										type="button"
										className="btn btn-sm konfisio-color-outline btn-outline-secondary px-2 mx-1"
										data-bs-toggle="modal"
										data-bs-target="#investModal"
									>
										Dados para Pagamento
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card shadow-sm">
							<div className="card-body">
								<p className="display-6"><b>Cursos Livres</b></p>
								<div>
									<p className="badge text-bg-secondary p-2 mx-1">Online</p>
									<p className="card-text ms-2">
										Formação em
										{' '}
										<b>Terapeuta Facial e Corporal</b>
									</p>
								</div>
								<div className="d-flex align-items-center mt-3">
									<a
										href="https://konfisio.com/ead"
										target="_blank"
										rel="noreferrer"
										className="btn btn-sm konfisio-color-bg btn-secondary px-2 mx-1"
									>
										Acessar Plataforma
									</a>
									<Link
										to="/cursos/21a14f10-48ba-11ed-9c5e-77c5b6904748"
										className="btn btn-sm konfisio-color-outline btn-outline-secondary px-2 mx-1"
									>
										Informações
									</Link>
								</div>
								<hr />
								<div>
									<p className="badge text-bg-secondary p-2 mx-1">Online</p>
									<p className="card-text ms-2">
										Capacitação em
										{' '}
										<b>Estética</b>
										<Link
											to="cursos/c006f9d0-5b74-11ed-9485-6d575b7a950d"
											className="btn btn-sm konfisio-color-outline btn-outline-secondary px-2 ms-2"
										>
											Informações
										</Link>
									</p>
									<p className="card-text ms-2">
										Capacitação em
										{' '}
										<b>Massoterapia</b>
										<Link
											to="cursos/b3e50210-5b73-11ed-9485-6d575b7a950d"
											className="btn btn-sm konfisio-color-outline btn-outline-secondary px-2 ms-2"
										>
											Informações
										</Link>
									</p>
									<p className="card-text ms-2">
										Capacitação em
										{' '}
										<b>Medicina Tradicional Chinesa</b>
									</p>
								</div>
								<div className="d-flex align-items-center mt-3 mb-4">
									<a
										href="https://konfisio.com/ead"
										target="_blank"
										rel="noreferrer"
										className="btn btn-sm konfisio-color-bg btn-secondary px-2 mx-1"
									>
										Acessar todas as Plataformas
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card shadow-sm">
							<img src={imgCursosOnline} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Online" />
							<div className="card-body">
								<p className="display-6"><b>Cursos Online</b></p>
								<p className="card-text mt-4">
									O aluno faz o curso
									<strong> online pelo smartphone, tablet ou computador </strong>
									através da
									<strong> plataforma Hotmart.</strong>
								</p>
								<div className="d-flex align-items-center">
									<Link
										to="/cursos-online"
										className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
									>
										Informações
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalInvestimento />
		</section>
	);
}
