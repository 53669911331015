export const toastTopFullWidth = {
	closeButton: true,
	debug: false,
	newestOnTop: false,
	progressBar: true,
	positionClass: 'toast-top-full-width',
	preventDuplicates: false,
	onclick: null,
	showDuration: '300',
	hideDuration: '1000',
	timeOut: '0',
	extendedTimeOut: '0',
	showEasing: 'swing',
	hideEasing: 'linear',
	showMethod: 'slideDown',
	hideMethod: 'slideUp',
};

export const toastTopRight = {
	closeButton: true,
	debug: false,
	newestOnTop: false,
	progressBar: true,
	positionClass: 'toast-top-right',
	preventDuplicates: false,
	onclick: null,
	showDuration: '300',
	hideDuration: '1000',
	timeOut: '5000',
	extendedTimeOut: '1000',
	showEasing: 'swing',
	hideEasing: 'linear',
	showMethod: 'slideDown',
	hideMethod: 'slideUp',
};
