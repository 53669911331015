import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
	Agenda, CursoDetalhes, CursosOnline, InfoPagamentos, ListaDeCursos,
	Maintenance, MatriculaOnline, NotFound404, PosGraduacao, PosGraduacaoDetalhes,
} from './pages/index';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AppContext from './context/AppContext';
import Home from './pages/Home';
import ProtectedRoute from './pages/panel/ProtectedRoute';
import Login from './pages/panel/Login';
import LoginAdmin from './pages/panel/LoginAdmin';
import Panel from './pages/panel/Panel';
import Config from './pages/panel/Config';
import Course from './pages/panel/Course/Course';
import CourseDetails from './pages/panel/Course/CourseDetails';
import AddNewCourse from './pages/panel/Course/AddNewCourse';
import EditCourse from './pages/panel/Course/EditCourse';
import Schedule from './pages/panel/Schedule/Schedule';
import Enrollments from './pages/panel/Enrollments/Enrollments';
import Graduation from './pages/panel/Graduation/Graduation';
import GraduationDetails from './pages/panel/Graduation/GraduationDetails';
import AddNewGraduation from './pages/panel/Graduation/AddNewGraduation';
import EditGraduation from './pages/panel/Graduation/EditGraduation';
import PlatformsEAD from './pages/panel/PlatformsEAD/PlatformsEAD';
import EditPlatformsEAD from './pages/panel/PlatformsEAD/EditPlatformsEAD';
import BackToTop from './components/BackToTop/BackToTop';

function App() {
	const { systemIsActive } = useContext(AppContext);

	return (
		<>
			<ScrollToTop />
			<Routes>
				{systemIsActive ? (
					<>
						<Route path="/" element={<Home />} />
						<Route path="/agenda" element={<Agenda />} />
						<Route path="/matricula-online" element={<MatriculaOnline />} />
						<Route path="/cursos-online" element={<CursosOnline />} />
						<Route path="/info-pagamentos" element={<InfoPagamentos />} />
						<Route path="/cursos" element={<ListaDeCursos />} />
						<Route path="/cursos/:id" element={<CursoDetalhes />} />
						<Route path="/posgraduacao/:id" element={<PosGraduacaoDetalhes />} />
						<Route path="/posgraduacao" element={<PosGraduacao />} />
						<Route path="/panel/login" element={<Login />} />
						<Route path="/panel" element={<ProtectedRoute component={Panel} />} />
						<Route path="/panel/course" element={<ProtectedRoute component={Course} />} />
						<Route path="/panel/course/:id" element={<ProtectedRoute component={CourseDetails} />} />
						<Route path="/panel/course/add" element={<ProtectedRoute component={AddNewCourse} />} />
						<Route path="/panel/course/edit/:id" element={<ProtectedRoute component={EditCourse} />} />
						<Route path="/panel/schedule" element={<ProtectedRoute component={Schedule} />} />
						<Route path="/panel/enrollments" element={<ProtectedRoute component={Enrollments} />} />
						<Route path="/panel/graduation" element={<ProtectedRoute component={Graduation} />} />
						<Route path="/panel/graduation/:id" element={<ProtectedRoute component={GraduationDetails} />} />
						<Route path="/panel/graduation/add" element={<ProtectedRoute component={AddNewGraduation} />} />
						<Route path="/panel/graduation/edit/:id" element={<ProtectedRoute component={EditGraduation} />} />
						<Route path="/panel/platforms-ead" element={<ProtectedRoute component={PlatformsEAD} />} />
						<Route path="/panel/platforms-ead/edit/:id" element={<ProtectedRoute component={EditPlatformsEAD} />} />
						<Route path="/panel/admin" element={<ProtectedRoute component={Config} />} />
						<Route path="*" element={<NotFound404 />} />
					</>
				) : (
					<>
						<Route path="/loginadmin" element={<LoginAdmin />} />
						<Route path="/panel/admin" element={<ProtectedRoute component={Config} />} />
						<Route path="*" element={<Maintenance />} />
					</>
				)}
			</Routes>
			<BackToTop />
		</>
	);
}

export default App;