import React from 'react';
import { getCurrentDate } from '../../utils/dates';

export default function ModalTermos() {
	return (
		// eslint-disable-next-line
		// @ts-ignore
		<div className="modal fade" id="contratoModal" tabIndex="-1" aria-labelledby="modalcontrato" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">Contrato</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<p>
							Pelo presente instrumento, a pessoa jurídica Konfisio,
							CNPJ número 29.228.309/0001-15,
							com sede na Avenida Gilberto Cardoso, número 44, Bairro: Turf-club,
							Campos dos Goytacazes/RJ, Cep: 28015-140, representado neste ato,
							pela sócia proprietária Karina Aparecida Barcelos Teixeira, brasileira,
							divorciada, inscrita no CPF número 081.036.457-37, portadora do
							RG número 09.806.793-7 ,
							residente e domiciliada na Rua Doutor Silvio Bastos Tavares, número 348,
							Parque Rodoviário, Campos dos Goytacazes/RJ, CEP: 28051-250 e de outro
							lado o contratante cujo os dados serão fornecidos no cadastro do aluno,
							firmam o presente contrato de curso profissionalizante,
							conforme as cláusulas a seguir:
						</p>
						<b>DO OBJETO DO CONTRATO</b>
						<ul>
							<li>
								<b>Cláusula primeira</b>
								{' '}
								- O presente contrato tem por objeto o curso livre escolhido pelo aluno
								no sítio da KONFISIO, sendo indispensável a observância das cláusulas
								deste instrumento para a conclusão da matrícula.
							</li>
						</ul>
						<b>DAS REGRAS PARA MATRÍCULA</b>
						<ul>
							<li>
								<b>Cláusula segunda</b>
								{' '}
								- Após o envio da ficha de inscrição formulada pelo contratante no sítio
								da contratada, o contratante deverá efetuar o
								{' '}
								<b>
									pagamento da matrícula no mesmo dia ou valor integral para isenção
									da matrícula.

								</b>
							</li>
							<li>
								<b>Cláusula terceira</b>
								{' '}
								- Caso o contratante não efetue o pagamento da matrícula, no prazo mencionado
								na cláusula anterior, será efetuado o cancelamento da matrícula do aluno.
							</li>
							<li>
								<b>Cláusula quarta</b>
								{' '}
								- Ficará isento do pagamento da taxa de matrícula, o aluno que efetuar o
								pagamento do curso profissionalizante à vista, ou efetuar o pagamento ou parcelar
								o curso baixando o aplicativo Pic Pay, com ordem de pagamento à contratada
								ou dividir no cartão de crédito, devendo comparecer na sede da contratada,
								na Avenida Gilberto Cardoso, número 44, Bairro: Turf-club,
								Campos dos Goytacazes/RJ, Cep: 28015-140, com exceção das matrículas
								realizadas um dia antes do curso.
							</li>
							<li>
								<b>Cláusula quinta</b>
								{' '}
								- As inscrições realizadas um dia antes do curso, independente da forma
								de pagamento não terá a isenção da matrícula.
							</li>
							<li>
								<b>Cláusula sexta</b>
								{' '}
								- Caso o aluno preencha uma ficha de inscrição e não efetue o pagamento
								do curso, ele não poderá preencher uma segunda ficha de inscrição, com
								o beneficio da isenção da matrícula, independente da forma de pagamento
								que o aluno escolher.
							</li>
							<li>
								<b>Cláusula sétima</b>
								{' '}
								- Caso a contratada faça a veiculação no seu sítio de alguma promoção
								de cursos, valerá as regras das promoções.
							</li>
							<li>
								<b>Cláusula oitava</b>
								{' '}
								- Caso sejam preenchidas todas as vagas de um curso profissionalizante
								oferecido pela contratada, o mesmo será avisado na coluna de datas de cursos.
							</li>
							<li>
								<b>Cláusula nona</b>
								{' '}
								- A matrícula só será válida após o envio da ficha e o pagamento da matrícula,
								e após o pagamento enviar o comprovante para matriculas@konfisio.com. Ao enviar
								o comprovante é necessário o nome completo do estudante.
							</li>
							<li>
								<b>Cláusula décima</b>
								{' '}
								- EEm caso de desistência do curso pelo contratante, só será devolvido 75%
								(setenta e cinco por cento) do valor pago. Caso o aluno só tenha quitado a
								matrícula, o valor correspondente a matrícula não será estornado.
							</li>
							<li>
								<b>Cláusula décima primeira</b>
								{' '}
								- A contratada reserva-se o direito de cancelar o curso caso não atinja o
								número mínimo de alunos, devendo estornar o valor integral pago pelo contratante.
							</li>
							<li>
								<b>Cláusula décima segunda</b>
								{' '}
								- Não será emitido nota fiscal para os cursos profissionalizantes, só sendo
								emitido para os cursos de pós-graduações que poderá ser abatido no imposto
								de renda do contratante.
							</li>
							<li>
								<b>Cláusula décima terceira</b>
								{' '}
								- A desistência do curso deve ser informada com até 48 hs de antecedência, caso
								contrário não será feito estorno do valor.
							</li>
						</ul>
						As partes elegem o foro da comarca de Campos dos Goytacazes/RJ para dirimir quaisquer
						controvérsias oriundas deste contrato, ficando excluído qualquer outro por mais
						privilegiado que seja.
						<br />
						Campos dos Goytacazes,
						{' '}
						{getCurrentDate()}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>
	);
}
