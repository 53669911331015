import React from 'react';

export default function ModalTermos() {
	return (
		// eslint-disable-next-line
		// @ts-ignore
		<div className="modal fade" id="termosModal" tabIndex="-1" aria-labelledby="modaltermos" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">Termos</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<ul>
							<li>
								Em caso de desistência, o aluno matriculado nesse curso terá o ônus de 25%
								sobre o valor  pago a título de taxa administrativa. Caso tenha pago só a
								matrícula, a mesma não será estornada.
							</li>
							<li>
								A Konfisio reserva-se o direito de cancelar o curso caso não atinja
								o número mínimo de alunos e estornar o valor real ao aluno matriculado.
							</li>
							<li>
								Não emitimos nota fiscal. Curso de extensão não é debitado
								em Imposto de renda, apenas pós-graduações.
							</li>
						</ul>
						Contatos: (22) 999061385 / matriculas@konfisio.com
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>
	);
}
