import React from 'react';
import { Link } from 'react-router-dom';

interface LiMainNavbarProps {
	href: string;
	innerText: string;
}

const LiPainelNavBar: React.FC<LiMainNavbarProps> = ({ href, innerText }) => {
	return (
		<li className="nav-item">
			<Link to={href} className="nav-link">{innerText}</Link>
		</li>
	);
};

export default LiPainelNavBar;
