import React from 'react';
import { Footer, InfoPagamentos as Info, MainNavbar } from '../components';

export default function InfoPagamentos() {
	return (
		<>
			<header className="mb-5">
				<MainNavbar />
			</header>
			<div className="container">
				<div className="row">
					<div className="col-12 mt-5">
						<h3 className="text-center">Informações sobre Pagamento</h3>
						<Info />
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
