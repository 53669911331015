import React from 'react';

function Footer() {

	const year = () => {
		const date = new Date();
		return date.getFullYear();
	};

	return (
		<footer className="container">
			<div className="py-3 my-4">
				<ul className="nav justify-content-center">
					<li className="nav-item">
						<a href="https://www.instagram.com/konfisioka/" className="nav-link px-2 text-muted">
							<i className="fa-brands fa-instagram fa-2x konfisio-color" />
						</a>
					</li>
					<li className="nav-item">
						<a href="https://www.facebook.com/konfisio/" className="nav-link px-2 text-muted">
							<i className="fa-brands fa-facebook fa-2x konfisio-color" />
						</a>
					</li>
					<li className="nav-item">
						<a href="https://api.whatsapp.com/send?phone=5522999329536&text=Ol%C3%A1%2C%20tenho%20interesse%20nos%20cursos%20da%20Konfisio" className="nav-link px-2 text-muted">
							<i className="fa-brands fa-whatsapp fa-2x konfisio-color" />
						</a>
					</li>
					<li className="nav-item">
						<a href="mailto:contato@konfiso.com" className="nav-link px-2 text-muted">
							<i className="fa-solid fa-envelope fa-2x konfisio-color" />
						</a>
					</li>
				</ul>
				<hr className="hr-konfisio mb-4" />
				<p className="text-center text-muted" style={{ lineHeight: '35px' }}>
					<strong>&quot;Para cima do medo, coragem!&quot;</strong>
					<br />
					{year()} &copy; Konfisio Cursos de Capacitação
					<br />
					Av. Gilberto Cardoso, 44 - Turf Club, Campos dos Goytacazes - RJ
				</p>
				<p className="text-center" style={{ lineHeight: '35px', fontSize: '9px', color: '#dddddd' }}>
					v: 0.0.003
				</p>
			</div>
		</footer>
	);
}

export default Footer;
