import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import toastr from 'toastr';
import Axios from '../api/axios';
import { URL_GET_COURSE_BY_ID } from '../api/apiUrls';
import { ICourse } from '../interfaces';
import { Footer, ImageCursos, MainNavbar, toastTopRight } from '../components';

export default function Curso() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const { id } = useParams();

	const [course, setCourse] = useState<ICourse>({} as ICourse);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getCourseById = async () => {
			await Axios.get(`${URL_GET_COURSE_BY_ID}/${id}`)
				.then((response) => setCourse(response.data[0]))
				.catch((error) => {
					toastr.error('Um erro aconteceu. Tente novamente.');
					console.log(error);
				});
			setIsLoading(false);
		};
		getCourseById();
	}, []);

	return (
		<>
			<header className="mb-4">
				<MainNavbar />
			</header>
			<section className="container mt-5">
				<div className="row">
					<div className="col-12">
						{isLoading && (
							<div className="text-center mt-5">
								<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
							</div>
						)}
						<div className="d-flex justify-content-between mt-5">
							<h3>{course.nome}</h3>
						</div>
						<ImageCursos />
						{/* {course.imagem && (
              <div className="my-4">
                <img
                  src={`https://ik.imagekit.io/xwugtefge/konfisio-site/courses/${course.imagem}?v=${Date.now()}`}
                  alt={course.nome}
                  loading="lazy"
                  className="img-fluid shadow-sm rounded-3 img-thumbnail"
                  style={{ minWidth: '250px', height: '250px' }}
                />
              </div>
            )} */}
						{course.descricao && (
							<div className="my-4">
								<p>
									<strong>Descrição:</strong>
									<br />
									{course.descricao}
								</p>
							</div>
						)}
						<div className="my-4">
							{course.publicoAlvo && (
								<p>
									<strong>Público Alvo:</strong>
									{' '}
									{course.publicoAlvo}
								</p>
							)}
							{course.cargaHoraria && (
								<p>
									<strong>Carga Horária:</strong>
									{' '}
									{course.cargaHoraria}
								</p>
							)}
							{course.horario && (
								<p>
									<strong>Horário:</strong>
									{' '}
									{course.horario}
								</p>
							)}
						</div>
						{course.investimento && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Investimento:</strong>
									<br />
									{course.investimento}
								</p>
							</div>
						)}
						{course.facilitadoresDoConhecimento && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Facilitadores do conhecimento:</strong>
									<br />
									{course.facilitadoresDoConhecimento}
								</p>
							</div>
						)}
						{course.informacoesAdicionais && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Informações adicionais:</strong>
									<br />
									{course.informacoesAdicionais}
								</p>
							</div>
						)}
						{course.conteudo && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Conteúdo:</strong>
									<br />
									{course.conteudo}
								</p>
							</div>
						)}
						<HashLink to="/matricula-online/#formmatricula" className="btn btn-lg konfisio-color-bg btn-secondary">Matrícula Online</HashLink>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}
