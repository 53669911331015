import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import toastr from 'toastr';
import { toastTopRight, PainelNavBar } from '../../../components';
import Axios from '../../../api/axios';
import { URL_GET_COURSE_BY_ID } from '../../../api/apiUrls';
import { ICourse } from '../../../interfaces';

export default function Curso() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const { id } = useParams();

	const [course, setCourse] = useState<ICourse>({} as ICourse);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getCourseById = async () => {
			await Axios.get(`${URL_GET_COURSE_BY_ID}/${id}`)
				.then((response) => setCourse(response.data[0]))
				.catch((error) => {
					toastr.error(`${error.message} - Entre em contato com o Administrador`);
				});
			setIsLoading(false);
		};
		getCourseById();
	}, []);

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/panel/course">
								<i className="fa-solid fa-list me-2" />
								Lista de Cursos
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to={`/panel/course/${course.idCurso}`}>
								<i className="fa-regular fa-file-lines me-2" />
								{course.nome}
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				{isLoading && (
					<div className="text-center">
						<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
					</div>
				)}
				<div className="row">
					<div className="col-12">
						<div className="d-flex justify-content-between">
							<h3>{course.nome}</h3>
							<Link to={`/panel/course/edit/${course.idCurso}`}>
								<button type="button" className="btn btn-md btn-secondary ms-5">
									<i className="fa-regular fa-pen-to-square me-2" />
									Editar
								</button>
							</Link>
						</div>
						<hr />
						{course.imagem && (
							<div className="my-4">
								<img
									src={`https://ik.imagekit.io/xwugtefge/konfisio-site/courses/${course.imagem}?v=${Date.now()}`}
									alt={course.nome}
									loading="lazy"
									className="img-fluid shadow-sm rounded-3 img-thumbnail"
									style={{ minWidth: '250px', height: '250px' }}
								/>
							</div>
						)}
						{course.descricao && (
							<div className="my-4">
								<p>
									<strong>Descrição:</strong>
									<br />
									{course.descricao}
								</p>
							</div>
						)}
						<div className="my-4">
							{course.publicoAlvo && (
								<p>
									<strong>Público Alvo:</strong>
									{' '}
									{course.publicoAlvo}
								</p>
							)}
							{course.cargaHoraria && (
								<p>
									<strong>Carga Horária:</strong>
									{' '}
									{course.cargaHoraria}
								</p>
							)}
							{course.horario && (
								<p>
									<strong>Horário:</strong>
									{' '}
									{course.horario}
								</p>
							)}
						</div>
						{course.investimento && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Investimento:</strong>
									<br />
									{course.investimento}
								</p>
							</div>
						)}
						{course.facilitadoresDoConhecimento && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Facilitadores do conhecimento:</strong>
									<br />
									{course.facilitadoresDoConhecimento}
								</p>
							</div>
						)}
						{course.informacoesAdicionais && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Informações adicionais:</strong>
									<br />
									{course.informacoesAdicionais}
								</p>
							</div>
						)}
						{course.conteudo && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Conteúdo:</strong>
									<br />
									{course.conteudo}
								</p>
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
}
