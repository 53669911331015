import React, { useState, useEffect, useContext, FormEvent, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { DateTime } from 'luxon';
import toastr from 'toastr';
import AppContext from '../../../context/AppContext';
import { PainelNavBar, toastTopRight } from '../../../components';
import Axios from '../../../api/axios';
import {
	URL_GET_ALL_COURSES,
	URL_GET_ALL_SCHEDULE,
	URL_ADD_NEW_SCHEDULE,
	URL_EDIT_SCHEDULE,
	URL_DELETE_SCHEDULED_COURSE,
} from '../../../api/apiUrls';
import { ISchedule } from '../../../interfaces';

export default function Schedule() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const { currentUser } = useContext(AppContext);

	const INITIAL_STATE = {
		idCurso: '',
		nome: '',
		dataInicial: '',
		dataFinal: '',
		local: '',
		createdBy: currentUser.idUser,
		modifiedBy: currentUser.idUser,
	};

	const [inputs, setInputs] = useState(INITIAL_STATE);

	const [schedule, setSchedule] = useState<ISchedule[]>([]);

	const [monthsSchedule, setMonthsSchedule] = useState<string[]>([]);

	const [isLoading, setIsLoading] = useState(false);

	const [isFetchLoading, setIsFetchLoading] = useState(false);

	const [currentElement, setCurrentElement] = useState<number>();

	const [showModalSchedule, setShowModalSchedule] = useState(false);

	const [showModalEditSchedule, setShowModalEditSchedule] = useState(false);

	const [inputsEdit, setInputsEdit] = useState(INITIAL_STATE);

	const [listOfCourses, setListOfCourses] = useState([]);

	const handleCloseModalSchedule = () => setShowModalSchedule(false);

	const handleShowModalSchedule = () => setShowModalSchedule(true);

	const handleFormInputs = ({ target }: ChangeEvent<HTMLInputElement>) => {
		setInputs((prevState) => ({ ...prevState, [target.name]: target.value }));
	};

	useEffect(() => {
		const getScheduledCourses = async () => {
			await Axios.get(URL_GET_ALL_SCHEDULE)
				.then((response) => {
					setSchedule(response.data);
					const months = (response.data).map((e: ISchedule) => DateTime.fromISO(e.dataInicial, { zone: 'utc' }).setLocale('pt-br').toFormat('MMMM/yyyy'));
					setMonthsSchedule([...new Set<string>(months)]);
				})
				.catch((error) => toastr.error(`${error.message} - Entre em contato com o Administrador`));
		};
		getScheduledCourses();
	}, [inputs]);

	const handleCloseModalEditSchedule = () => setShowModalEditSchedule(false);

	const handleEditFormInputs = ({ target }: ChangeEvent<HTMLInputElement>) => {
		setInputsEdit((prevState) => ({ ...prevState, [target.name]: target.value }));
	};

	const handleShowModalEditSchedule = (element: number) => {
		const scheduleToEdit = schedule.find((e) => Number(e.index) === Number(element));
		if (scheduleToEdit) {
			const newState = {
				...scheduleToEdit,
				dataInicial: DateTime.fromISO(scheduleToEdit.dataInicial, { zone: 'utc' }).toFormat('yyyy-MM-dd'),
				dataFinal: DateTime.fromISO(scheduleToEdit.dataFinal, { zone: 'utc' }).toFormat('yyyy-MM-dd'),
			};
			setInputsEdit(newState);
			setShowModalEditSchedule(true);
		}
	};

	useEffect(() => {
		setIsFetchLoading(true);
		const getListOfCourses = async () => {
			await Axios.get(URL_GET_ALL_COURSES)
				.then((response) => setListOfCourses(response.data))
				.catch((error) => toastr.error(`${error.message} - Entre em contato com o Administrador`));
			setIsFetchLoading(false);
		};
		getListOfCourses();
	}, []);

	const validateMinimumDate = () => {
		if (inputs.dataInicial === '') {
			return setInputs({ ...inputs, dataInicial: inputs.dataFinal });
		}
		if (inputs.dataInicial > inputs.dataFinal && inputs.dataFinal === '') {
			return setInputs({ ...inputs, dataFinal: inputs.dataInicial });
		}
		if (inputs.dataInicial > inputs.dataFinal) {
			return setInputs({ ...inputs, dataFinal: inputs.dataInicial });
		}
		return true;
	};

	useEffect(() => {
		validateMinimumDate();
	}, [inputs.dataInicial, inputs.dataFinal]);

	const validateMinimumDateEdit = () => {
		if (inputsEdit.dataInicial === '') {
			return setInputsEdit({ ...inputsEdit, dataInicial: inputsEdit.dataFinal });
		}
		if (inputsEdit.dataInicial > inputsEdit.dataFinal && inputsEdit.dataFinal === '') {
			return setInputsEdit({ ...inputsEdit, dataFinal: inputsEdit.dataInicial });
		}
		if (inputsEdit.dataInicial > inputsEdit.dataFinal) {
			return setInputsEdit({ ...inputsEdit, dataFinal: inputsEdit.dataInicial });
		}
		return true;
	};

	useEffect(() => {
		validateMinimumDateEdit();
	}, [inputsEdit.dataInicial, inputsEdit.dataFinal]);

	const handleFormSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
		if (target.value) {
			setInputs((prevState) => ({
				...prevState,
				[target.name]: target.value,
				// eslint-disable-next-line
				// @ts-ignore
				nome: listOfCourses.filter((e) => e.idCurso === target.value)[0].nome,
			}));
		}
	};

	const handleEditFormSelect = ({ target }: ChangeEvent<HTMLInputElement>) => {
		if (target.value) {
			setInputsEdit((prevState) => ({
				...prevState,
				[target.name]: target.value,
				// eslint-disable-next-line
				// @ts-ignore
				nome: listOfCourses.filter((e) => e.idCurso === target.value)[0].nome,
			}));
		}
	};

	const formatDate = (initiaLDate: string, finalDate: string) => {
		const formatInitialDate = DateTime.fromISO(initiaLDate, { zone: 'utc' }).toFormat('dd/MM/yy');
		const formatFinalDate = DateTime.fromISO(finalDate, { zone: 'utc' }).toFormat('dd/MM/yy');
		if (formatInitialDate === formatFinalDate) return formatInitialDate;
		return `${formatInitialDate} a ${formatFinalDate}`;
	};

	const cancelModalEditSchedule = () => {
		setInputsEdit(INITIAL_STATE);
		handleCloseModalEditSchedule();
	};

	const cancelModalSchedule = () => {
		setInputs(INITIAL_STATE);
		handleCloseModalSchedule();
	};

	// const deleteScheduledCourseOnDataBase = async (scheduleToDelete) => {
	//   await Axios.delete(URL_DELETE_SCHEDULED_COURSE, {
	//     data: {
	//       indexSchedule: scheduleToDelete.indexSchedule,
	//       idCurso: scheduleToDelete.idCurso,
	//     },
	//   })
	//     .then(async () => {
	//       await Axios.get(URL_GET_ALL_SCHEDULE)
	//         .then((response) => {
	//           setSchedule(response.data);
	//           const months = (response.data).map((e) => DateTime.fromISO(e.dataInicial, { zone: 'utc' }).setLocale('pt-br').toFormat('MMMM/yyyy'));
	//           setMonthsSchedule([...new Set(months)]);
	//         })
	//         .catch((error) => {
	//           toastr.error(`${error.message} - Entre em contato com o Administrador`);
	//         });
	//       toastr.success('Agendamento excluído');
	//     })
	//     .catch((error) => {
	//       toastr.error('Um erro aconteceu. Tente novamente.', error);
	//     });
	// };

	const deleteScheduledCourseOnDataBase = async (scheduleToDelete: { indexSchedule: number, idCurso: string }) => {
		await Axios.post(URL_DELETE_SCHEDULED_COURSE, scheduleToDelete)
			.then(async () => {
				await Axios.get(URL_GET_ALL_SCHEDULE)
					.then((response) => {
						setSchedule(response.data);
						const months = (response.data).map((e: ISchedule) => DateTime.fromISO(e.dataInicial, { zone: 'utc' }).setLocale('pt-br').toFormat('MMMM/yyyy'));
						setMonthsSchedule([...new Set<string>(months)]);
					})
					.catch((error) => {
						toastr.error(`${error.message} - Entre em contato com o Administrador`);
					});
				toastr.success('Agendamento excluído');
			})
			.catch((error) => {
				toastr.error('Um erro aconteceu. Tente novamente.', error);
			});
		setIsLoading(false);
	};

	const deleteScheduleCourse = async (elementIndex: number, elementIdCourse: string) => {
		setCurrentElement(elementIndex);
		setIsLoading(true);
		const scheduleToDelete = {
			indexSchedule: elementIndex,
			idCurso: elementIdCourse,
		};
		await deleteScheduledCourseOnDataBase(scheduleToDelete);
		setCurrentElement(0);
	};

	const saveScheduleCourseOnDataBase = async () => {
		setIsLoading(true);
		await Axios.post(URL_ADD_NEW_SCHEDULE, inputs)
			.then(() => {
				toastr.success('Curso agendado com sucesso!');
				setInputs(INITIAL_STATE);
			})
			.catch(() => {
				toastr.error('Um erro aconteceu. Tente novamente.');
			});
		setIsLoading(false);
	};

	const scheduleCourse = async (event: FormEvent) => {
		event.preventDefault();
		await saveScheduleCourseOnDataBase();
		handleCloseModalSchedule();
	};

	const saveEditedScheduleCourseOnDataBase = async () => {
		setIsLoading(true);
		await Axios.put(URL_EDIT_SCHEDULE, inputsEdit)
			.then(() => {
				toastr.success('Alterações salvas com sucesso!');
				setInputs(INITIAL_STATE);
				setInputsEdit(INITIAL_STATE);
			})
			.catch(() => {
				toastr.error('Um erro aconteceu. Tente novamente.');
			});
		setIsLoading(false);
	};

	const editScheduleCourse = async (event: FormEvent) => {
		event.preventDefault();
		await saveEditedScheduleCourseOnDataBase();
		handleCloseModalEditSchedule();
	};

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/schedule">
								<i className="fa-solid fa-calendar-days me-2" />
								Agenda de Cursos
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row">
					<div className="col-12 mt-2">
						<button
							type="button"
							className="btn btn-md btn-primary"
							onClick={handleShowModalSchedule}
							disabled={isLoading}
						>
							<i className="fa-solid fa-calendar-plus me-2" />
							Agendar Curso
						</button>
						{isFetchLoading && (
							<div className="text-center">
								<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
							</div>
						)}
						{monthsSchedule.length > 0
							&& monthsSchedule.map((e) => {
								const month = e;
								const monthUpperCase = e[0].toUpperCase() + e.substring(1);
								return (
									<ul key={e} className="list-group list-group-flush mt-4">
										<b>{monthUpperCase}</b>
										{schedule.filter((date) => month === DateTime.fromISO(date.dataInicial, { zone: 'utc' })
											.setLocale('pt-br')
											.toFormat('MMMM/yyyy'))
											.map((element, index) => (
												<li key={element.index} className={`list-group-item ${index % 2 === 0 && 'bg-light'}`}>
													<div className="row align-items-center justify-content-evenly">
														<div className="col-7 col-md-7 col-lg-8">
															<span className="mt-2">
																{`${formatDate(element.dataInicial, element.dataFinal)} 
                                ${element.local && `(${element.local})`} - `}
																<b>
																	{element.nome}
																</b>
															</span>
														</div>
														<div className="col-5 col-md-5 col-lg-4 text-center">
															<button
																type="button"
																id={`${element.index}`}
																className="btn btn-md btn-secondary mx-2 my-2"
																onClick={() => handleShowModalEditSchedule(element.index)}
																disabled={isLoading}
															>
																<i className="fa-regular fa-pen-to-square me-md-2" />
																<span className="d-none d-sm-none d-md-inline">Editar</span>
															</button>
															{currentElement === element.index ? (
																<button className="btn btn-md btn-danger mx-2 my-2" type="button" disabled>
																	<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
																	<span className="d-none d-sm-none d-md-inline">&nbsp; Excluindo...</span>
																</button>
															) : (

																<button
																	type="button"
																	className="btn btn-md btn-danger mx-2 my-2"
																	onClick={() => {
																		deleteScheduleCourse(element.index, element.idCurso);
																	}}
																	disabled={isLoading}
																>
																	<i className="fa-solid fa-trash me-md-2" />
																	<span className="d-none d-sm-none d-md-inline">Excluir</span>
																</button>
															)}
														</div>
													</div>
												</li>
											))}
										<span />
									</ul>
								);
							})}
					</div>
				</div>
				<Modal show={showModalSchedule} centered>
					<Modal.Header>
						<Modal.Title id="modalSchedule" className="text-primary">
							<i className="fa-solid fa-calendar-plus me-2" />
							Agendar Curso
						</Modal.Title>
					</Modal.Header>
					<form onSubmit={scheduleCourse}>
						<div className="modal-body">
							<div className="row justify-content-center my-2">
								<div className="col-lg-12">
									<select
										name="idCurso"
										className="form-control form-select my-2"
										onChange={handleFormSelect as any} // eslint-disable-line
										value={inputs.idCurso}
										disabled={isLoading}
										required
									>
										<option value="">Clique para selecionar um curso</option>
										{listOfCourses.map((curso: { idCurso: string, nome: string }) => (
											<option key={curso.idCurso} value={curso.idCurso}>{curso.nome}</option>
										))}
									</select>
								</div>
							</div>
							<div className="row justify-content-center my-2">
								<div className="col-lg-6">
									<label htmlFor="dataInicial" className="form-label" style={{ width: '100%' }}>
										Data Inicial
										<input
											name="dataInicial"
											type="date"
											className="form-control"
											placeholder="Data Inicial"
											value={inputs.dataInicial}
											onChange={handleFormInputs}
											disabled={isLoading}
											required
										/>
									</label>
								</div>
								<div className="col-lg-6">
									<label htmlFor="dataFinal" className="form-label" style={{ width: '100%' }}>
										Data Final
										<input
											name="dataFinal"
											type="date"
											className="form-control"
											placeholder="Data Inicial"
											value={inputs.dataFinal}
											onChange={handleFormInputs}
											disabled={isLoading}
											required
										/>
									</label>
								</div>
							</div>
							<div className="row justify-content-center my-2">
								<div className="col-lg-12">
									<label htmlFor="local" className="form-label" style={{ width: '100%' }}>
										<input
											name="local"
											type="text"
											className="form-control"
											placeholder="Local"
											value={inputs.local}
											onChange={handleFormInputs}
											disabled={isLoading}
										/>
									</label>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-outline-danger"
								data-bs-dismiss="modal"
								onClick={cancelModalSchedule}
								disabled={isLoading}
							>
								Cancelar
							</button>
							{isLoading
								? (
									<button
										type="submit"
										className="btn btn-primary"
										disabled
									>
										<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
										Salvando...
									</button>
								)
								: (
									<button
										type="submit"
										className="btn btn-primary"
										disabled={isLoading}
									>
										<i className="fa-solid fa-calendar-plus me-2" />
										Salvar
									</button>
								)}
						</div>
					</form>
				</Modal>
				<Modal show={showModalEditSchedule} centered>
					<Modal.Header>
						<Modal.Title id="modalSchedule" className="text-primary">
							<i className="fa-solid fa-calendar-day me-2" />
							Editar Agendamento
						</Modal.Title>
					</Modal.Header>
					<form onSubmit={editScheduleCourse}>
						<div className="modal-body">
							<div className="row justify-content-center my-2">
								<div className="col-lg-12">
									<select
										name="idCurso"
										className="form-control form-select my-2"
										onChange={handleEditFormSelect as any} // eslint-disable-line
										value={inputsEdit.idCurso}
										disabled={isLoading}
										required
									>
										{listOfCourses.map((curso: { idCurso: string, nome: string }) => (
											<option key={curso.idCurso} value={curso.idCurso}>
												{curso.nome}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="row justify-content-center my-2">
								<div className="col-lg-6">
									<label htmlFor="dataInicial" className="form-label" style={{ width: '100%' }}>
										Data Inicial
										<input
											name="dataInicial"
											type="date"
											className="form-control"
											placeholder="Data Inicial"
											value={inputsEdit.dataInicial}
											onChange={handleEditFormInputs}
											disabled={isLoading}
											required
										/>
									</label>
								</div>
								<div className="col-lg-6">
									<label htmlFor="dataFinal" className="form-label" style={{ width: '100%' }}>
										Data Final
										<input
											name="dataFinal"
											type="date"
											className="form-control"
											placeholder="Data Inicial"
											value={inputsEdit.dataFinal}
											onChange={handleEditFormInputs}
											disabled={isLoading}
											required
										/>
									</label>
								</div>
							</div>
							<div className="row justify-content-center my-2">
								<div className="col-lg-12">
									<label htmlFor="local" className="form-label" style={{ width: '100%' }}>
										<input
											name="local"
											type="text"
											className="form-control"
											placeholder="Local"
											value={inputsEdit.local}
											onChange={handleEditFormInputs}
											disabled={isLoading}
										/>
									</label>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-outline-danger"
								data-bs-dismiss="modal"
								onClick={cancelModalEditSchedule}
								disabled={isLoading}
							>
								Cancelar
							</button>
							{isLoading
								? (
									<button
										type="submit"
										className="btn btn-primary"
										disabled
									>
										<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
										Alterando...
									</button>
								)
								: (
									<button
										type="submit"
										className="btn btn-primary"
										disabled={isLoading}
									>
										<i className="fa-solid fa-calendar-day me-2" />
										Alterar
									</button>
								)}
						</div>
					</form>
				</Modal>
			</section>
		</>
	);
}
