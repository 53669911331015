import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Axios from '../../../api/axios';
import { URL_GET_ALL_GRADUATIONS } from '../../../api/apiUrls';
import { IGraduation } from '../../../interfaces';

export default function PosGraduacao() {
	const [graduations, setGraduations] = useState<IGraduation[]>([]);

	const [graduationsError, setGraduationsError] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getGraduations = async () => {
			await Axios.get(URL_GET_ALL_GRADUATIONS)
				.then((response) => {
					const graduationsActive = response.data.filter((graduation: IGraduation) => graduation.isActive === 1);
					setGraduations(graduationsActive);
					setGraduationsError(false);
				})
				.catch(() => setGraduationsError(true));
			setIsLoading(false);
		};
		getGraduations();
	}, []);

	return (
		<section id="posgraduacao" className="container my-5">
			<div className="row justify-content-center align-items-center">
				<h2 className="text-center rounded-3 p-3 shadow-sm" style={{ background: 'var(--primary-theme-color)', color: 'white' }}>
					<i className="fa-solid fa-graduation-cap me-2" />
					Pós-graduação
				</h2>
				{isLoading && (
					<div className="text-center">
						<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
					</div>
				)}
				{graduationsError && (
					<div className="alert alert-danger">
						Houve um erro ao recuperar esse conteúdo, tente novamente mais tarde.
					</div>
				)}
				<div className="col-11">
					<ul className="list-group list-group-flush mt-2 p-3">
						<h5
							className="text-start"
							style={{ color: 'var(--primary-theme-color)', fontWeight: 'bold' }}
						>
							Em Andamento
						</h5>
						{graduations.filter((graduation) => graduation.nome.includes('Em Andamento'))
							.map((e, index) => (
								<li
									key={e.index}
									className={`list-group-item p-3 ${index % 2 === 1 && 'bg-light'}`}
									style={{ color: '#3ca2a2', fontSize: '1.2rem' }}
								>
									<Link to={`/posgraduacao/${e.idGraduacao}`} className="agendalink">
										{e.nome}
									</Link>
								</li>
							))}
					</ul>
					<ul className="list-group list-group-flush mt-2 p-3">
						<h5
							className="text-start"
							style={{ color: 'var(--primary-theme-color)', fontWeight: 'bold' }}
						>
							Agendadas
						</h5>
						{graduations.filter((graduation) => !graduation.nome.includes('Em Andamento'))
							.map(graduation => {
								let dataInicio: Date | null = null;
								const dataString = graduation.nome.match(/\d{2}\/\d{2}\/\d{2}$/)?.[0];
								if (dataString) {
									const [dia, mes, ano] = dataString.split('/').map(Number);
									dataInicio = new Date(2000 + ano, mes - 1, dia);
								} else {
									dataInicio = new Date();
								}
								return { ...graduation, dataInicio };
							})
							.sort((a, b) => a.dataInicio.getTime() - b.dataInicio.getTime())
							.map((e, index) => (
								<li
									key={e.index}
									className={`list-group-item p-3 ${index % 2 === 1 && 'bg-light'}`}
									style={{ color: '#3ca2a2', fontSize: '1.2rem' }}
								>
									<Link to={`/posgraduacao/${e.idGraduacao}`} className="agendalink">
										{e.nome}
									</Link>
								</li>
							))}
					</ul>
					<HashLink
						to="/matricula-online"
						type="button"
						className="btn btn-lg btn-light mx-auto d-block my-3 d-none"
					>
						Clique aqui para fazer a matrícula
					</HashLink>
				</div>
			</div>
		</section>
	);
}
