import React, { useState, useEffect, useRef, FormEvent, ChangeEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import toastr from 'toastr';
import Axios from '../../../api/axios';
import {
	URL_GET_GRADUATION_BY_ID, URL_EDIT_GRADUATION, URL_IMAGEKIT_AUTHENTICATION, BASE_URL,
} from '../../../api/apiUrls';
import { PainelNavBar, toastTopRight } from '../../../components';
import IKUploadK from '../../../components/IKUpload/index';

export default function EditGraduation() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	// TODO: remover espaço em branco no início e final dos inputs
	// TODO: automatizar o createdBy com a identificação do current user
	const INITIAL_STATE = {
		idGraduacao: '',
		nome: '',
		descricao: '',
		local: '',
		publicoAlvo: '',
		cargaHoraria: '',
		coordenacao: '',
		corpoDocente: '',
		investimento: '',
		informacoesAdicionais: '',
		conteudo: '',
		modifiedBy: 'admin',
		imagem: '',
	};

	const [inputs, setInputs] = useState(INITIAL_STATE);

	const { id } = useParams();

	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const [isLoading, setLoading] = useState(false);

	const [hasNewImage, setHasNewImage] = useState(false);

	const childCompRef = useRef();

	useEffect(() => {
		const getGraduationById = async () => {
			await Axios.get(`${URL_GET_GRADUATION_BY_ID}/${id}`)
				.then((response) => setInputs(response.data[0]))
				.catch((error) => { console.log(error); });
		};
		getGraduationById();
	}, []);

	const validateRequiredFormInputs = () => {
		const inputsToValidate = [inputs.nome, inputs.investimento, inputs.conteudo];
		setIsButtonDisabled(inputsToValidate.some((input) => input.length === 0));
	};

	useEffect(() => {
		setInputs((prevState) => ({ ...prevState, nameNewImage: inputs.idGraduacao }));
	}, [hasNewImage]);

	const thereIsImageInDB = () => {
		if (inputs.imagem) {
			setInputs((prevState) => ({ ...prevState, nameNewImage: inputs.idGraduacao }));
		}
	};

	useEffect(() => {
		thereIsImageInDB();
	}, [inputs.nome, isLoading]);

	const handleFormInputs = ({ target }: ChangeEvent<HTMLInputElement>) => {
		setInputs((prevState) => ({ ...prevState, [target.name]: target.value }));
		validateRequiredFormInputs();
	};

	const updateGraduationInDataBase = async () => {
		await Axios.put(URL_EDIT_GRADUATION, inputs)
			.then(async () => {
				// eslint-disable-next-line
				// @ts-ignore
				if (hasNewImage) await childCompRef.current.uploadFile();
				await Axios.get(`${URL_GET_GRADUATION_BY_ID}/${id}`)
					.then((response) => setInputs(response.data[0]))
					.catch((error) => { console.log(error); });
				toastr.success('Alterações salvas com sucesso!');
				setIsButtonDisabled(true);
				setLoading(false);
			})
			.catch((error) => {
				toastr.error(`Um erro aconteceu. Tente novamente.${error}`);
				setLoading(false);
			});
	};

	const updateGraduation = async (event: FormEvent) => {
		setLoading(true);
		event.preventDefault();
		await updateGraduationInDataBase();
	};

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/panel/graduation">
								<i className="fa-solid fa-graduation-cap me-2" />
								Pós-graduação
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to={`/panel/graduation/edit/${id}`}>
								<i className="fa-regular fa-pen-to-square me-2" />
								Editar Pós-graduação
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<form onSubmit={updateGraduation} className="row justify-content-center">
					<div className="col-12 col-lg-6">
						<div className="row p-md-2">
							<label htmlFor="nome" className="form-label col-lg-12 my-2">
								<strong>Nome</strong>
								<span style={{ color: 'red' }}> *</span>
								<input
									name="nome"
									type="text"
									className="form-control"
									placeholder="Nome do curso"
									value={inputs.nome}
									onChange={handleFormInputs}
									disabled={isLoading}
									required
								/>
							</label>
							<label htmlFor="descricao" className="form-label col-lg-12 my-2">
								<strong>Descrição</strong>
								<textarea
									name="descricao"
									className="form-control"
									placeholder="Descrição do curso"
									value={inputs.descricao}
									style={{ resize: 'none' }}
									rows={4}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="local" className="form-label col-lg-12 my-2">
								<strong>Local</strong>
								<input
									name="local"
									type="text"
									className="form-control"
									placeholder="Local"
									value={inputs.local}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="publicoAlvo" className="form-label col-lg-12 my-2">
								<strong>Público Alvo</strong>
								<input
									name="publicoAlvo"
									type="text"
									className="form-control"
									placeholder="Qual é o público alvo?"
									value={inputs.publicoAlvo}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="cargaHoraria" className="form-label col-lg-12 my-2">
								<strong>Carga Horária</strong>
								<input
									name="cargaHoraria"
									type="text"
									className="form-control"
									placeholder="Carga Horária"
									value={inputs.cargaHoraria}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
							{inputs.imagem ? (
								<div className="col-lg-12 my-2">
									<div className="row align-items-center">
										<div className="col-12 col-lg-5 text-start" style={{ height: '210px' }}>
											<strong>Imagem</strong>
											<div className="border rounded-3 p-3">
												<img
													src={`https://ik.imagekit.io/xwugtefge/konfisio-site/courses/${inputs.imagem}?v=${Date.now()}`}
													alt={inputs.nome}
													className="img-fluid shadow-sm rounded-3 mx-auto d-block"
													style={{ maxHeight: '100px' }}
												/>
												<button
													type="button"
													className="mx-auto d-block btn btn-sm btn-outline-secondary mx-2 mt-3"
													onClick={() => { setInputs({ ...inputs, imagem: '' }); }}
												>
													Alterar imagem
												</button>
											</div>
										</div>
										<label htmlFor="investimento" className="form-label col-lg-7 my-2">
											<strong>Investimento</strong>
											<span style={{ color: 'red' }}> *</span>
											<textarea
												name="investimento"
												className="form-control"
												placeholder="Investimento"
												value={inputs.investimento}
												onChange={handleFormInputs as any} // eslint-disable-line
												style={{ resize: 'none', height: '180px' }}
												rows={4}
												disabled={isLoading}
												required
											/>
										</label>
									</div>
								</div>
							) : (
								<>
									<div className="form-label col-lg-12 my-2">
										<strong>Imagem</strong>
										<IKUploadK
											// eslint-disable-next-line
											// @ts-ignore
											fileName={inputs.nameNewImage}
											tags={[inputs.nome]}
											useUniqueFileName={false}
											folder="/konfisio-site/courses"
											publicKey="public_JdnEOcQ+bNlV9WYwU8XtZgSJj7Y="
											urlEndpoint="https://ik.imagekit.io/xwugtefge"
											authenticationEndpoint={`${BASE_URL}${URL_IMAGEKIT_AUTHENTICATION}`}
											onChangeFunction={[setHasNewImage, hasNewImage]}
											setDadComponentStateImage={() => { }} // eslint-disable-line
											disabled={isLoading}
											ref={childCompRef}
										/>
									</div>
									<label htmlFor="investimento" className="form-label col-lg-12 my-2">
										<strong>Investimento</strong>
										<span style={{ color: 'red' }}> *</span>
										<textarea
											name="investimento"
											className="form-control"
											placeholder="Investimento"
											value={inputs.investimento}
											style={{ resize: 'none' }}
											rows={3}
											onChange={handleFormInputs as any} // eslint-disable-line
											disabled={isLoading}
											required
										/>
									</label>
								</>
							)}
							<label htmlFor="cargaHoraria" className="form-label col-lg-12 my-2">
								<strong>Coordenação Geral</strong>
								<input
									name="coordenacao"
									type="text"
									className="form-control"
									placeholder="Coordenação Geral"
									value={inputs.coordenacao}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
						</div>
					</div>
					<div className="col-12 col-lg-6">
						<div className="row p-md-2">
							<label htmlFor="corpoDocente" className="form-label col-lg-12 my-2">
								<strong>Corpo Docente</strong>
								<textarea
									name="corpoDocente"
									className="form-control"
									placeholder="Corpo Docente"
									value={inputs.corpoDocente}
									style={{ resize: 'none' }}
									rows={7}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="conteudo" className="form-labe col-lg-12 my-2">
								<strong>Conteúdo</strong>
								<span style={{ color: 'red' }}> *</span>
								<textarea
									name="conteudo"
									className="form-control"
									placeholder="Conteúdo do curso"
									value={inputs.conteudo}
									style={inputs.imagem ? { resize: 'none', height: '370px' } : { resize: 'none', height: '340px' }}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
									required
								/>
							</label>
							<label htmlFor="informacoesAdicionais" className="form-label col-lg-12 my-2">
								<strong>Informações Adicionais</strong>
								<textarea
									name="informacoesAdicionais"
									className="form-control"
									placeholder="Informações que não se enquadram nos outros campos"
									value={inputs.informacoesAdicionais}
									style={{ resize: 'none', height: '104px' }}
									rows={3}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
								/>
							</label>
						</div>
					</div>
					<div className="col-12 col-lg-6 text-center">
						{isLoading
							? (
								<button type="submit" className="btn btn-md btn-primary col-12 col-lg-6 mx-auto mt-3" disabled>
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
									Salvando...
								</button>
							)
							: (
								<button
									type="submit"
									className="btn btn-md btn-primary col-12 col-lg-6 mx-auto mt-3"
									disabled={isButtonDisabled}
								>
									<i className="fa-regular fa-floppy-disk me-2" />
									Salvar Curso
								</button>
							)}
						<br />
						<span style={{ color: 'red', fontSize: '14px' }}>* Informações obrigatórias</span>
					</div>
				</form>
			</section>
		</>
	);
}
