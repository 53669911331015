import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PainelNavBar } from '../../../components';
import Axios from '../../../api/axios';
import { URL_GET_GRADUATION_BY_ID } from '../../../api/apiUrls';
import { IGraduation } from '../../../interfaces';

export default function Curso() {
	const { id } = useParams();

	const [graduation, setGraduation] = useState<IGraduation>({} as IGraduation);

	useEffect(() => {
		const getListOfGraduations = async () => {
			await Axios.get(`${URL_GET_GRADUATION_BY_ID}/${id}`)
				.then((response) => setGraduation(response.data[0]))
				.catch((error) => { console.log(error); });
		};
		getListOfGraduations();
	}, []);

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/panel/graduation">
								<i className="fa-solid fa-graduation-cap me-2" />
								Pós-graduação
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to={`/panel/graduation/${graduation.idGraduacao}`}>
								<i className="fa-regular fa-file-lines me-2" />
								{graduation.nome}
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row">
					<div className="col-12">
						<div className="d-flex justify-content-between">
							<h3>{graduation.nome}</h3>
							<Link to={`/panel/graduation/edit/${graduation.idGraduacao}`}>
								<button type="button" className="btn btn-md btn-secondary ms-5">
									<i className="fa-regular fa-pen-to-square me-2" />
									Editar
								</button>
							</Link>
						</div>
						<hr />
						{graduation.imagem && (
							<div className="my-4">
								<img
									src={`https://ik.imagekit.io/xwugtefge/konfisio-site/graduations/${graduation.imagem}?v=${Date.now()}`}
									alt={graduation.nome}
									loading="lazy"
									className="img-fluid shadow-sm rounded-3 img-thumbnail"
									style={{ minWidth: '250px', height: '250px' }}
								/>
							</div>
						)}
						{graduation.descricao && (
							<div className="my-4">
								<p>
									<strong>Descrição:</strong>
									<br />
									{graduation.descricao}
								</p>
							</div>
						)}
						<div className="my-4">
							{graduation.publicoAlvo && (
								<p>
									<strong>Público Alvo:</strong>
									{' '}
									{graduation.publicoAlvo}
								</p>
							)}
							{graduation.cargaHoraria && (
								<p>
									<strong>Carga Horária:</strong>
									{' '}
									{graduation.cargaHoraria}
								</p>
							)}
						</div>
						{graduation.investimento && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Investimento:</strong>
									<br />
									{graduation.investimento}
								</p>
							</div>
						)}
						{graduation.informacoesAdicionais && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Informações adicionais:</strong>
									<br />
									{graduation.informacoesAdicionais}
								</p>
							</div>
						)}
						{graduation.conteudo && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Conteúdo:</strong>
									<br />
									{graduation.conteudo}
								</p>
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
}
