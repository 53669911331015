import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import toastr from 'toastr';
import Axios from '../api/axios';
import { URL_GET_GRADUATION_BY_ID } from '../api/apiUrls';
import { IGraduation } from '../interfaces';
import { Footer, ImageCursos, MainNavbar, toastTopRight } from '../components';

export default function PosGraduacao() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const { id } = useParams();

	const [graduation, setGraduation] = useState<IGraduation>({} as IGraduation);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getListOfGraduations = async () => {
			await Axios.get(`${URL_GET_GRADUATION_BY_ID}/${id}`)
				.then((response) => setGraduation(response.data[0]))
				.catch((error) => {
					toastr.error('Um erro aconteceu. Tente novamente.');
					console.log(error);
				});
			setIsLoading(false);
		};
		getListOfGraduations();
	}, []);

	return (
		<>
			<header className="mb-4">
				<MainNavbar />
			</header>
			<section className="container mt-5">
				<div className="row">
					<div className="col-12">
						{isLoading && (
							<div className="text-center mt-5">
								<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
							</div>
						)}
						<div className="d-flex justify-content-between mt-5">
							<h3>{graduation.nome}</h3>
						</div>
						<hr />
						<ImageCursos />
						{/* {graduation.imagem && (
              <div className="my-4">
                <img
                  src={`https://ik.imagekit.io/xwugtefge/konfisio-site/graduations/${graduation.imagem}?v=${Date.now()}`}
                  alt={graduation.nome}
                  loading="lazy"
                  className="img-fluid shadow-sm rounded-3 img-thumbnail"
                  style={{ minWidth: '250px', height: '250px' }}
                />
              </div>
            )} */}
						{graduation.descricao && (
							<div className="my-4">
								<p>
									<strong>Descrição:</strong>
									<br />
									{graduation.descricao}
								</p>
							</div>
						)}
						<div className="my-4">
							{graduation.publicoAlvo && (
								<p>
									<strong>Público Alvo:</strong>
									{' '}
									{graduation.publicoAlvo}
								</p>
							)}
							{graduation.cargaHoraria && (
								<p>
									<strong>Carga Horária:</strong>
									{' '}
									{graduation.cargaHoraria}
								</p>
							)}
						</div>
						{graduation.investimento && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Investimento:</strong>
									<br />
									{graduation.investimento}
								</p>
							</div>
						)}
						{graduation.informacoesAdicionais && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Informações adicionais:</strong>
									<br />
									{graduation.informacoesAdicionais}
								</p>
							</div>
						)}
						{graduation.conteudo && (
							<div className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
								<p>
									<strong>Conteúdo:</strong>
									<br />
									{graduation.conteudo}
								</p>
							</div>
						)}
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}
