import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import { PainelNavBar } from '../../components';

export default function Panel() {
	const { currentUser } = useContext(AppContext);
	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item active">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row justify-content-evenly">
					<Link to="/panel/schedule" className="col-10 col-md-4 col-lg-3 col-xl-2 btn btn-lg btn-primary m-2">
						<i className="fa-solid fa-calendar-days fa-lg me-3" />
						<span className="fs-6">Agenda de Cursos</span>
					</Link>
					<Link to="/panel/course" className="col-10 col-md-4 col-lg-3 col-xl-2 btn btn-lg btn-primary m-2">
						<i className="fa-solid fa-list fa-lg me-3" />
						<span className="fs-6">Lista de Cursos</span>
					</Link>
					<Link to="/panel/graduation" className="col-10 col-md-4 col-lg-3 col-xl-2 btn btn-lg btn-primary m-2">
						<i className="fa-solid fa-graduation-cap fa-lg me-3" />
						<span className="fs-6">Pós-graduação</span>
					</Link>
					<Link to="/panel/enrollments" className="col-10 col-md-4 col-lg-3 btn btn-lg col-xl-2 btn-primary m-2">
						<i className="fa-regular fa-address-book fa-lg me-3" />
						<span className="fs-6">Matrículas Online</span>
					</Link>
					<Link
						to="/panel/platforms-ead"
						className={`col-10 col-md-4 col-lg-3 btn btn-lg col-xl-2 btn-primary m-2 ${currentUser.permissionLevel !== 0 && 'disabled'}`}
					>
						<i className="fa-solid fa-desktop fa-lg me-3" />
						<span className="fs-6">Plataformas EAD</span>
					</Link>
					{currentUser.permissionLevel === 0 && (
						<Link to="/panel/admin" className="col-10 col-md-4 col-lg-3 btn btn-lg col-xl-2 btn-primary m-2">
							<i className="fa-solid fa-gears fa-lg me-3" />
							<span className="fs-6">Configurações</span>
						</Link>
					)}
				</div>
			</section>
		</>
	);
}
