import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './context/AppProvider';
import App from './App';
import './assets/css/global.css';
import 'toastr/build/toastr.min.css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

root.render(
	// <React.StrictMode> // Em desenvolvimento executa tudo 2 vezes. Exemplo, console.log aparecem repetidos
	<BrowserRouter basename="/">
		<AppProvider>
			<App />
		</AppProvider>
	</BrowserRouter>,
	// </React.StrictMode>,
);
