import React, { useState, useEffect, useContext, FormEvent, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { toastTopRight } from '../../components';
import Axios from '../../api/axios';
import { URL_GET_USER_BY_EMAIL } from '../../api/apiUrls';
import favicon from '../../assets/img/logo-brand.png';
import AppContext from '../../context/AppContext';

export default function Login() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const INITIAL_STATE = {
		email: '',
		password: '',
	};

	const [inputsLoginForm, setInputsLoginForm] = useState(INITIAL_STATE);

	const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);

	const [isLoading, setIsLoading] = useState(false);

	const [isInvalid, setIsInvalid] = useState(false);

	const { setIsUserLoggedIn, setCurrentUser } = useContext(AppContext);

	const navigate = useNavigate();

	const validateRequiredFormInputs = () => {
		const email = /^[^@^ ]+@[^@^ ]+\.[a-z]{2,3}(\.[a-z]{2})?$/.test(inputsLoginForm.email);
		const password = inputsLoginForm.password.length > 5;
		return !(email && password);
	};

	const handleFormInputs = ({ target }: ChangeEvent<HTMLInputElement>) => { // eslint-disable-line
		setIsInvalid(false);
		setInputsLoginForm((prevState) => ({ ...prevState, [target.name]: target.value }));
	};

	useEffect(() => {
		setIsLoginButtonDisabled(validateRequiredFormInputs());
	}, [inputsLoginForm]);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		setIsLoading(true);
		await Axios.post(URL_GET_USER_BY_EMAIL, inputsLoginForm)
			.then((response) => {
				if (response.data.length > 0) {
					setCurrentUser({
						idUser: response.data[0].idUser,
						name: response.data[0].name,
						email: response.data[0].email,
						permissionLevel: response.data[0].permissionLevel,
					});
					setIsUserLoggedIn(true);
					navigate('/panel');
					setIsLoading(false);
				} else {
					toastr.error(response.data.message);
					setIsInvalid(true);
					setInputsLoginForm(INITIAL_STATE);
					setIsLoading(false);
				}
			})
			.catch(() => {
				toastr.error('Erro - Entre em contato com o Administrador');
				setIsLoading(false);
			});
	};

	return (
		<main style={{ background: '#f1f1f1' }}>
			<section className="container-fluid">
				<div className="row justify-content-center align-items-center h100">
					<form
						onSubmit={handleSubmit}
						className="col-10 col-md-6 col-lg-4 border rounded-3 p-4 shadow-sm"
						style={{ maxWidth: '350px', background: 'white' }}
					>
						<img
							src={favicon}
							className="img-fluid mx-auto d-block mb-4"
							alt="Trybewallet"
							style={{ maxWidth: '80px' }}
						/>
						<div className="input-group mb-3">
							<span className="input-group-text text-secondary" id="basic-addon1">
								<i className="fa-solid fa-envelope" />
							</span>
							<input
								type="email"
								name="email"
								value={inputsLoginForm.email}
								placeholder="E-mail"
								autoFocus //eslint-disable-line
								className={`form-control ${isInvalid && 'is-invalid'}`}
								onChange={handleFormInputs}
								required
							/>
						</div>

						<div className="input-group mb-3">
							<span className="input-group-text text-secondary" id="basic-addon1">
								<i className="fa-solid fa-key" />
							</span>
							<input
								type="password"
								name="password"
								value={inputsLoginForm.password}
								placeholder="Senha"
								className={`form-control ${isInvalid && 'is-invalid'}`}
								onChange={handleFormInputs}
								required
							/>
						</div>
						{isLoading
							? (
								<button className="btn btn-primary d-block mx-auto mt-4 form-control" type="button" disabled>
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
									Acessando...
								</button>
							)
							: (
								<button
									type="submit"
									className={
										`btn btn-md btn-primary d-block mx-auto mt-4 form-control
            ${isLoginButtonDisabled && 'btn-secondary disabled'}`
									}
									disabled={isLoginButtonDisabled}
								>
									Acessar
								</button>
							)}
					</form>
				</div>
			</section>
		</main>
	);
}
