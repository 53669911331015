import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import toastr from 'toastr';
import { PainelNavBar, toastTopFullWidth } from '../../../components';
import Axios from '../../../api/axios';
import { URL_GET_PLATFORM_EAD_MODULES, URL_GET_ALL_CLASSES } from '../../../api/apiUrls';
import { IModule, IClass } from '../../../interfaces';

export default function EditPlatformsEAD() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopFullWidth;

	const { id } = useParams();

	const [listOfModules, setListOfModules] = useState<IModule[]>([]);

	const [listOfClasses, setListOfClasses] = useState<IClass[]>([]);

	useEffect(() => {
		const getPlatformModules = async () => {
			await Axios.get(`${URL_GET_PLATFORM_EAD_MODULES}/${id}`)
				.then((response) => setListOfModules(response.data))
				.catch((error) => { console.log(error); });
		};
		getPlatformModules();
		const getAllClasses = async () => {
			await Axios.get(`${URL_GET_ALL_CLASSES}`)
				.then((response) => setListOfClasses(response.data))
				.catch((error) => { console.log(error); });
		};
		getAllClasses();
	}, []);

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/platforms-ead">
								<i className="fa-solid fa-desktop me-2" />
								Plataformas EAD
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/platforms-ead">
								<i className="fa-regular fa-folder me-2" />
								Terapeuta Facial e Corporal
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row">
					<div className="col-12 mt-2 text-center">
						<Link to="/panel/platforms-ead/add" className="btn btn-primary mx-2 my-2 disabled">
							<i className="fa-solid fa-folder-plus me-2" />
							<span className="d-none d-md-inline">Adicionar </span>
							Módulo
						</Link>
						<Link to="/panel/platforms-ead/add" className="btn btn-primary mx-2 my-2 disabled">
							<i className="fa-brands fa-youtube me-2" />
							<span className="d-none d-md-inline">Adicionar </span>
							Aula
						</Link>
						<Link to="/panel/platforms-ead/add" className="btn btn-primary mx-2 my-2 disabled">
							<i className="fa-solid fa-user-plus me-2" />
							<span className="d-none d-md-inline">Adicionar </span>
							Aluno
						</Link>
						<div className="accordion accordion-flush my-3 text-start" id="accordionModules">
							{listOfModules.map((module) => (
								<div key={module.index} className="accordion-item">
									<h2 className="accordion-header" id="flush-headingOne">
										<button
											className="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target={`#${module.nome.replace(' ', '')}`}
											aria-expanded="false"
											aria-controls={module.nome.replace(' ', '')}
										>
											<i className="fa-regular fa-folder-closed me-2 text-primary" />
											{module.nome}
										</button>
									</h2>
									<div id={module.nome.replace(' ', '')} className="accordion-collapse collapse" aria-labelledby={module.nome.replace(' ', '')} data-bs-parent="#accordionModules">
										<div className="accordion-body">
											<ul className="list-group list-group-flush list-group-numbered">
												{listOfClasses.map((e) => (
													e.idModulo === module.idModulo && (
														<li
															key={e.nome}
															className="list-group-item"
															style={{ cursor: 'pointer' }}
															role="presentation"
															onClick={() => console.log(e.videoAula)}
														>
															<i className="fa-brands fa-youtube me-2" />
															{e.nome}
														</li>
													)
												))}
											</ul>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
