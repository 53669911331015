export const localhostIP = 'http://192.168.0.45:3001';

export const cpfMask = (value: string) => value
	.replace(/\D/g, '') // substitui qualquer caractere que nao seja numero por nada
	.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
	.replace(/(\d{3})(\d)/, '$1.$2')
	.replace(/(\d{3})(\d{1,2})/, '$1-$2')
	.replace(/(-\d{2})\d+?$/, '$1');

export const phoneMask = (value: string) => value
	.replace(/\D/g, '')
	.replace(/(\d{2})(\d)/, '($1)$2')
	.replace(/(\d{5})(\d)/, '$1-$2');

export const cepMask = (value: string) => value
	.replace(/\D/g, '')
	.replace(/(\d{5})(\d{1,2})/, '$1-$2')
	.replace(/(-\d{3})\d+?$/, '$1');
