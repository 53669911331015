import React from 'react';

export default function Maintenance() {
	return (
		<section
			className="container-fluid"
			style={{
				height: '100vh',
				maxHeight: '100vh',
			}}
		>
			<div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
				<div className="col-10 col-md-6 col-lg-4">
					<h5 className="alert alert-primary">Em manutenção</h5>
					<h5 className="mt-5 mb-3">
						Nossos sistemas estão em manutenção no momento.
						Tente novamente mais tarde ou entre em contato clicando no botão abaixo.
					</h5>
					<a
						href="https://api.whatsapp.com/send?phone=5522999329536&text=Ol%C3%A1%2C%20tenho%20interesse%20nos%20cursos%20da%20Konfisio"
						className="btn btn-success my-3 mb-4"
						target="_blank"
						rel="noreferrer"
					>
						<i className="fa-brands fa-whatsapp me-2" />
						Entrar em contato
					</a>
				</div>
			</div>
		</section>
	);
}
