import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import toastr from 'toastr';
import emailjs from '@emailjs/browser';
import { DateTime } from 'luxon';
import Axios from '../api/axios';
import { URL_ADD_NEW_ENROLLMENT } from '../api/apiUrls';
import { cpfMask, phoneMask, cepMask } from '../utils/helpers';
import { AtencaoAluno, Footer, MainNavbar, ModalContrato, ModalInvestimento, ModalTermos, toastTopRight } from '../components';

export default function MatriculaOnline() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const INITIAL_STATE = {
		idMatricula: '',
		curso: '',
		nome: '',
		sobrenome: '',
		email: '',
		cpf: '',
		dataNascimento: '',
		celular: '',
		genero: 'Não informar',
		profissao: '',
		carteiraProfissional: '',
		cep: '',
		endereco: '',
		cidade: '',
		uf: 'RJ',
		contrato: false,
		termos: false,
	};

	const [inputs, setInputs] = useState(INITIAL_STATE);

	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const [isLoading, setLoading] = useState(false);

	const [showModalConfirmation, setShowModalConfirmation] = useState(false);

	const validateRequiredFormInputs = () => {
		const inputsToValidate = [
			inputs.curso, inputs.nome, inputs.sobrenome,
			inputs.email, inputs.cpf, inputs.dataNascimento, inputs.celular,
			inputs.profissao, inputs.endereco, inputs.cidade, inputs.uf,
		];
		return inputsToValidate.some((input) => input.length === 0);
	};

	const validateRequiredFormCheckbox = () => {
		const checkboxToValidate = [inputs.contrato, inputs.termos];
		return checkboxToValidate.some((input) => input === false);
	};

	const isValideEmail = () => /\S+@\S+\.\S+/.test(inputs.email);

	useEffect(() => {
		setIsButtonDisabled(validateRequiredFormInputs()
			|| validateRequiredFormCheckbox()
			|| !isValideEmail());
	}, [inputs]);

	const handleFormInputs = ({ target }: any) => { // eslint-disable-line
		if (target.name === 'cpf') {
			const cpfMasked = cpfMask(target.value);
			return setInputs({ ...inputs, cpf: cpfMasked });
		}
		if (target.name === 'celular') {
			const phoneMasked = phoneMask(target.value);
			return setInputs({ ...inputs, celular: phoneMasked });
		}
		if (target.name === 'cep') {
			const cpfMasked = cepMask(target.value);
			return setInputs({ ...inputs, cep: cpfMasked });
		}
		return setInputs({ ...inputs, [target.name]: target.value });
	};

	const handleFormCheckbox = (e: any) => setInputs({ ...inputs, [e.target.name]: e.target.checked }); // eslint-disable-line

	const generateUniqueId = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
		const threeFirstsCpfNumbers = inputs.cpf.substr(0, 3);
		return `${year}${month}${threeFirstsCpfNumbers}${date.getTime()}`;
	};

	useEffect(() => {
		if (inputs.cpf.length >= 3) {
			setInputs({ ...inputs, idMatricula: generateUniqueId() });
		}
	}, [inputs.cpf]);

	const sendConfirmationEmail = async () => {
		const templateParams = {
			idMatricula: inputs.idMatricula,
			curso: inputs.curso,
			client_email: inputs.email,
			client_name: inputs.nome,
			client_fullname: `${inputs.nome} ${inputs.sobrenome}`,
			cpf: inputs.cpf,
			email: inputs.email,
			dataNascimento: DateTime.fromISO(inputs.dataNascimento).toFormat('dd/MM/yy'),
			celular: inputs.celular,
			profissao: inputs.profissao,
			carteiraProfissional: inputs.carteiraProfissional,
			genero: inputs.genero,
			cep: inputs.cep,
			endereco: inputs.endereco,
			cidade: inputs.cidade,
			uf: inputs.uf,
		};

		emailjs.send('service_s5wlvr5', 'template_9ga90vi', templateParams, 'o6_DtZvIYngeQuQg6')
			.then((response) => {
				console.log('SUCCESS!', response.status, response.text);
			}, (error) => {
				console.log('FAILED...', error);
			});
	};

	const saveEnrollmentInDataBase = async () => {
		await Axios.post(URL_ADD_NEW_ENROLLMENT, inputs)
			.then(async () => {
				toastr.success('Matrícula realizada com sucesso!');
				await sendConfirmationEmail();
				setInputs(INITIAL_STATE);
				setShowModalConfirmation(true);
				setLoading(false);
			})
			.catch(() => {
				toastr.error('Um erro aconteceu. Tente novamente.');
				setLoading(false);
			});
	};

	const enviarMatricula = async (e: any) => { // eslint-disable-line
		e.preventDefault();
		setLoading(true);
		await saveEnrollmentInDataBase();
	};

	const UF = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB',
		'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

	return (
		<>
			<header className="mb-4">
				<MainNavbar />
			</header>
			<div id="formmatricula" />
			<section className="container mt-5" style={{ maxWidth: '1000px' }}>
				<div className="row">
					<div className="col-12 mt-5 text-center">
						<AtencaoAluno />
					</div>
				</div>
				<form onSubmit={enviarMatricula} className="row justify-content-center rounded-3 mt-3 ">
					<h3 className="text-center">Formulário de Matrícula</h3>
					<span className="text-center mb-3" style={{ color: 'red', fontSize: '14px' }}>* Informações obrigatórias</span>
					<label htmlFor="curso" className="form-label col-lg-5 my-2">
						<strong>Qual curso deseja fazer?</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="curso"
							type="text"
							className="form-control"
							placeholder="Digite o nome do curso"
							value={inputs.curso}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="nome" className="form-label col-12 col-lg-3 my-2">
						<strong>Nome</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="nome"
							type="text"
							className="form-control"
							placeholder="Digite o seu nome"
							value={inputs.nome}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="sobrenome" className="form-label col-12 col-lg-4 my-2">
						<strong>Sobrenome</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="sobrenome"
							type="text"
							className="form-control"
							placeholder="Digite o seu sobrenome"
							value={inputs.sobrenome}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="email" className="form-label col-lg-5 my-2">
						<strong>Email</strong>
						<span style={{ color: 'red' }}> *</span>
						{(!isValideEmail() && inputs.email.length >= 5) && <span className="text-center" style={{ color: 'red' }}> Use um e-mail válido</span>}
						<input
							name="email"
							type="text"
							className="form-control"
							placeholder="Digite o seu email"
							value={inputs.email.toLowerCase()}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="cpf" className="form-label col-6 col-lg-3 my-2">
						<strong>CPF</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="cpf"
							maxLength={14}
							type="text"
							className="form-control"
							placeholder="Digite o seu CPF"
							value={inputs.cpf}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="dataNascimento" className="form-label col-6 col-lg-2 my-2">
						<strong>Nascimento</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="dataNascimento"
							type="date"
							className="form-control"
							placeholder="Digite sua data de nascimento"
							value={inputs.dataNascimento}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="genero" className="form-label col-5 col-lg-2 my-2">
						<strong>Gênero</strong>
						<select
							className="form-select"
							name="genero"
							value={inputs.genero}
							onChange={handleFormInputs}
							disabled={isLoading}
						>
							<option value="Não informar">Não informar</option>
							<option value="Feminino">Feminino</option>
							<option value="Masculino">Masculino</option>
						</select>
					</label>
					<label htmlFor="celular" className="form-label col-7 col-lg-3 my-2">
						<strong>Celular</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="celular"
							maxLength={14}
							type="text"
							className="form-control"
							placeholder="DDD + Número"
							value={inputs.celular}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="profissao" className="form-label col-12 col-lg-4 my-2">
						<strong>Profissão</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="profissao"
							type="text"
							className="form-control"
							placeholder="Profissão"
							value={inputs.profissao}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="carteiraProfissional" className="form-label col-12 col-lg-3 my-2">
						<strong>Carteira Profissional</strong>
						<input
							name="carteiraProfissional"
							type="text"
							className="form-control"
							placeholder="Carteira Profissional"
							value={inputs.carteiraProfissional}
							onChange={handleFormInputs}
							disabled={isLoading}
						/>
					</label>
					<label htmlFor="cep" className="form-label col-12 col-lg-2 my-2">
						<strong>CEP</strong>
						<input
							name="cep"
							type="text"
							className="form-control"
							placeholder="Digite o CEP"
							value={inputs.cep}
							onChange={handleFormInputs}
							disabled={isLoading}
						/>
					</label>
					<label htmlFor="endereco" className="form-label col-12 col-lg-6 my-2">
						<strong>Endereço</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="endereco"
							type="text"
							className="form-control"
							placeholder="Digite o seu endereço"
							value={inputs.endereco}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="cidade" className="form-label col-9 col-lg-4 my-2">
						<strong>Cidade</strong>
						<span style={{ color: 'red' }}> *</span>
						<input
							name="cidade"
							type="text"
							className="form-control"
							placeholder="Qual é a cidade?"
							value={inputs.cidade}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						/>
					</label>
					<label htmlFor="uf" className="form-label col-3 col-lg-2 my-2">
						<strong>UF</strong>
						<span style={{ color: 'red' }}> *</span>
						<select
							className="form-select"
							name="uf"
							value={inputs.uf}
							onChange={handleFormInputs}
							disabled={isLoading}
							required
						>
							{UF.map((e) => <option key={e} value={e}>{e}</option>)}
						</select>
					</label>
					<div className="row">
						<div className="col-12 text-center">
							<p className="text-start mt-3">
								<b>Investimento:</b>
								&nbsp;
								<a href="#modalinvestimento" data-bs-toggle="modal" data-bs-target="#investModal">Clique aqui</a>
								&nbsp; para visualizar as opções.
							</p>
							<p className="text-start mt-3">
								<b>Validação da Matrícula:</b>
								&nbsp; a matrícula só será válida após o envio da ficha e o pagamento da matrícula,
								e após o pagamento enviar o comprovante para matriculas@konfisio.com. Ao enviar o
								comprovante é necessário o nome completo do estudante.
							</p>
							<div className="alert alert-danger mt-3">
								<b>
									Favor ler atentamente o contrato, as regras e os termos de matrícula.
									E só enviar a ficha se realmente tiver intenção de validar sua matrícula
									efetuando o pagamento.
								</b>
							</div>
						</div>
					</div>
					<label htmlFor="contrato" className="form-label col-12 col-lg-12 my-2">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								checked={inputs.contrato}
								// eslint-disable-next-line
								// @ts-ignore
								value={inputs.contrato}
								name="contrato"
								onChange={handleFormCheckbox}
								required
							/>
							Li e aceito o contrato.
							<span style={{ color: 'red' }}>* </span>
							<a href="#modalcontrato" data-bs-toggle="modal" data-bs-target="#contratoModal">Clique aqui</a>
							&nbsp; para ler.
						</div>
					</label>
					<label htmlFor="termos" className="form-label col-12 col-lg-12 my-2">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								checked={inputs.termos}
								// eslint-disable-next-line
								// @ts-ignore
								value={inputs.termos}
								name="termos"
								onChange={handleFormCheckbox}
								required
							/>
							Li e concordo com os termos.
							<span style={{ color: 'red' }}>* </span>
							<a href="#modaltermos" data-bs-toggle="modal" data-bs-target="#termosModal">Clique aqui</a>
							&nbsp; para ler.
						</div>
					</label>
					<button
						type="submit"
						className="btn btn-md konfisio-color-bg btn-secondary col-11 col-lg-6 mx-auto mt-4"
						disabled={isButtonDisabled}
					>
						Enviar Matrícula
					</button>
					{
						isButtonDisabled
							? <span className="text-center" style={{ color: 'red' }}>* Preencha todos os campos obrigatórios</span>
							: <span>&nbsp;</span>
					}
				</form>
			</section>
			<Modal show={showModalConfirmation} centered>
				<Modal.Header>
					<Modal.Title id="modalSchedule" className="text-success">
						<i className="fa-solid fa-check me-2" />
						Sucesso
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Sua inscrição foi enviada com sucesso.</p>
					<p>
						Em instantes você receberá um e-mail confirmando todas informações
						que você já teve acesso durante o processo de inscrição.
					</p>
					<p>
						<b>
							Para validar sua matrícula
							{' '}
						</b>
						você deve
						{' '}
						<b>
							efetuar o pagamento e enviar o
							comprovante
						</b>
						{' '}
						para matriculas@konfisio.com. Lembre-se de informar seu nome na mensagem.
					</p>
					<Link to="/" className="btn btn-md btn-primary">Voltar ao site</Link>
				</Modal.Body>
			</Modal>
			<ModalInvestimento />
			<ModalTermos />
			<ModalContrato />
			<Footer />
		</>
	);
}
