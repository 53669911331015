// BASE_URL
export const BASE_URL = 'https://api.konfisio.com';

// COURSE
export const URL_GET_ALL_COURSES = '/courses';
export const URL_GET_COURSE_BY_ID = '/getCourseById';
export const URL_ADD_NEW_COURSE = '/addNewCourse';
export const URL_EDIT_COURSE = '/editCourse';

// SCHEDULE
export const URL_GET_ALL_SCHEDULE = '/schedule';
export const URL_ADD_NEW_SCHEDULE = '/addNewSchedule';
export const URL_EDIT_SCHEDULE = '/editScheduledCourse';
export const URL_DELETE_SCHEDULED_COURSE = '/deleteScheduledCourse';

// GRADUATION
export const URL_GET_ALL_GRADUATIONS = '/graduations';
export const URL_GET_GRADUATION_BY_ID = '/getGraduationById';
export const URL_ADD_NEW_GRADUATION = '/addNewGraduation';
export const URL_EDIT_GRADUATION = '/editGraduation';
export const URL_EDIT_ACTIVE_GRADUATION = '/editActiveGraduation';

// IMAGEKIT
export const URL_IMAGEKIT_AUTHENTICATION = '/imagekitAuthentication';

// ONLINE ENROLLMENT
export const URL_ADD_NEW_ENROLLMENT = '/addNewEnrollment';
export const URL_GET_LATEST_ENROLLMENTS = '/getLatestEnrollments';
export const URL_GET_ALL_ENROLLMENTS = '/getAllEnrollments';

// PLATFORMS_EAD
export const URL_GET_ALL_PLATFORMS_EAD = '/getAllPlatformsEAD';
export const URL_GET_PLATFORM_EAD_MODULES = '/getPlatformEADModules';
export const URL_GET_ALL_CLASSES = '/getAllClasses';

// SYSTEMS
export const URL_GET_ALL_SYSTEMS = '/getAllSystems';
export const URL_GET_SYSTEM_BY_ID = '/getSystemById';
export const URL_EDIT_ACTIVE_SYSTEM = '/editActiveSystem';

// USERS
export const URL_GET_USER_BY_EMAIL = '/getUserByEmail';
