import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import toastr from 'toastr';
import Axios from '../../../api/axios';
import { URL_GET_LATEST_ENROLLMENTS } from '../../../api/apiUrls';
import { PainelNavBar, toastTopRight } from '../../../components';
import { IEnrollment } from '../../../interfaces';

export default function Enrollments() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const [listOfEnrollments, setListOfEnrollments] = useState<IEnrollment[]>([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getLatestEnrollments = async () => {
			await Axios.get(`${URL_GET_LATEST_ENROLLMENTS}/20`)
				.then((response) => {
					setListOfEnrollments(response.data);
				})
				.catch((error) => {
					toastr.error(`${error.message} - Entre em contato com o Administrador`);
				});
			setIsLoading(false);
		};
		getLatestEnrollments();
	}, []);

	const getLatestEnrollments = async (limit: number) => {
		setIsLoading(true);
		await Axios.get(`${URL_GET_LATEST_ENROLLMENTS}/${limit}`)
			.then((response) => {
				setListOfEnrollments(response.data);
			})
			.catch((error) => {
				toastr.error(`${error.message} - Entre em contato com o Administrador`);
			});
		setIsLoading(false);
	};

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/enrollments">
								<i className="fa-regular fa-address-book me-2" />
								Matrículas Online
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<h5>Últimas inscrições realizadas</h5>
				{isLoading && (
					<div className="text-center">
						<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
					</div>
				)}
				<div className="row">
					<div className="col-12 mt-2">
						<div className="table-responsive">
							<table className="table table-striped">
								<thead className="table-primary">
									<tr>
										<th scope="col">Nome</th>
										<th scope="col">Telefone</th>
										<th scope="col">E-mail</th>
										<th scope="col">Curso</th>
										<th scope="col">Data</th>
									</tr>
								</thead>
								<tbody className="table-group-divider">
									{listOfEnrollments.map((enrollment) => (
										<tr key={enrollment.index}>
											<td>{`${enrollment.nome} ${enrollment.sobrenome}`}</td>
											<td>{enrollment.celular}</td>
											<td>{enrollment.email}</td>
											<td>{enrollment.curso}</td>
											<td>{DateTime.fromISO(enrollment.inscrito_em).toFormat('dd/MM/yyyy HH:mm:ss')}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						{isLoading ? (
							<button
								type="button"
								className="btn btn-primary mt-3"
								disabled
							>
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
								Carregando...
							</button>
						)
							: (
								<button
									type="button"
									className="btn btn-primary my-3"
									onClick={() => getLatestEnrollments(100)}
								>
									<i className="fa-solid fa-circle-plus me-2" />
									Mostrar mais
								</button>
							)}

					</div>
				</div>
			</section>
		</>
	);
}
