import axios from 'axios';

export default axios.create({
	baseURL: 'https://api.konfisio.com',
	headers: {
		Authorization: '50d021f0-f03e-466a-a0c0-4d49982e924c',
	},
});

// https://api.konfisio.com
// http://localhost:3001
// http://192.168.0.45:3001
