import React from 'react';
import InfoPagamentos from '../InfoPagamentos/InfoPagamentos';

export default function ModalInvestimento() {
	return (
		// eslint-disable-next-line
		// @ts-ignore
		<div className="modal fade" id="investModal" tabIndex="-1" aria-labelledby="modalinvesitmento" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">Investimento</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
					</div>
					<div className="modal-body">
						<InfoPagamentos />
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>
	);
}
