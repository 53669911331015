import React, { useState, forwardRef, useImperativeHandle } from 'react';
import COMMON_PROPS from 'imagekitio-react/src/components/IKContext/props';
import toastr from 'toastr';
import IK_UPLOAD_PROPS from './props';
import getIKClient from './getIkClient';

const PROP_TYPES = {
	...COMMON_PROPS,
	...IK_UPLOAD_PROPS,
};

const IKUpload = forwardRef((props, ref) => { // eslint-disable-line
	toastr.options = {
		closeButton: true,
		debug: false,
		newestOnTop: false,
		progressBar: true,
		positionClass: 'toast-top-right',
		preventDuplicates: false,
		onclick: null,
		showDuration: '300',
		hideDuration: '1000',
		timeOut: '5000',
		extendedTimeOut: '1000',
		showEasing: 'swing',
		hideEasing: 'linear',
		showMethod: 'slideDown',
		hideMethod: 'slideUp',
	};
	const [imageFile, setImageFile] = useState(undefined);
	const [inputUploadImageFile, setInputUploadImageFile] = useState('');

	const validateFileExtension = (event, onChangeFunction) => {
		const allowedExtensions = ['image/png', 'image/jpg', 'image/jpeg'];
		if (!allowedExtensions.includes(event.target.files[0].type)) {
			toastr.error('Só são permitidas imagens do tipo png, jpg e jpeg');
			setImageFile('');
			setInputUploadImageFile('');
			return false;
		}
		setInputUploadImageFile(event.target.value);
		const [onChangeFunctionPos1, onChangeFunctionPos2] = onChangeFunction;
		onChangeFunctionPos1(!onChangeFunctionPos2);
		setImageFile(event);
		return true;
	};

	useImperativeHandle(ref, () => ({
		uploadFile() {
			const {
				fileName,
				useUniqueFileName,
				tags,
				folder,
				isPrivateFile,
				customCoordinates,
				responseFields,
				onError,
				onSuccess,
			} = props;

			const { publicKey } = props;
			const { authenticationEndpoint } = props;

			const ikClientK = getIKClient();

			if (imageFile !== undefined) {
				const file = imageFile.target.files[0];

				const params = {
					file,
					fileName: fileName || file.name,
					useUniqueFileName,
					tags,
					folder,
					isPrivateFile,
					customCoordinates,
					responseFields,
				};

				ikClientK.upload(params, (err, result) => {
					if (err) {
						if (onError && typeof onError === 'function') {
							onError(err);
						}
					} else if (onSuccess && typeof onSuccess === 'function') {
						onSuccess(result);
					}
				}, {
					publicKey,
					authenticationEndpoint,
				});
				setImageFile('');
				setInputUploadImageFile('');
				return true;
			}
			return false;
		},
	}));

	const {
		publicKey, // eslint-disable-line
		urlEndpoint, // eslint-disable-line
		authenticationEndpoint, // eslint-disable-line
		fileName, // eslint-disable-line
		useUniqueFileName, // eslint-disable-line
		tags, // eslint-disable-line
		folder, // eslint-disable-line
		isPrivateFile, // eslint-disable-line
		customCoordinates, // eslint-disable-line
		responseFields, // eslint-disable-line
		onError, // eslint-disable-line
		onSuccess, // eslint-disable-line
		disabled,
		onChangeFunction,
		setDadComponentStateImage,
		...restProps
	} = props;

	return (
		<input
			type="file"
			className="form-control"
			/* eslint-disable react/jsx-props-no-spreading */
			{...restProps}
			onChange={(e) => {
				validateFileExtension(e, onChangeFunction);
				setDadComponentStateImage();
			}}
			value={inputUploadImageFile}
			disabled={disabled}
		/>
	);
});

IKUpload.defaultProps = {
	useUniqueFileName: true,
	isPrivateFile: false,
	customCoordinates: '',
	tags: [],
	folder: '/',
	responseFields: [],
};

IKUpload.propTypes = PROP_TYPES;

export default IKUpload;
