import React from 'react';
import { AgendaDeCursos, GaleriaDeFotos, Footer, MainNavbar, PosGraduacao, SectionCourses, SectionHero } from '../components';

export default function Home() {
	return (
		<>
			<header className="mb-5">
				<MainNavbar />
			</header>
			<SectionHero />
			<AgendaDeCursos />
			<PosGraduacao />
			<SectionCourses />
			<GaleriaDeFotos />
			<Footer />
		</>
	);
}
