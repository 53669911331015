import React from 'react';
import konfisioCursos from '../../assets/img/konfisio-cursos.webp';

function ImageCursos() {
	return (
		<div className="my-4 text-center">
			<img
				src={konfisioCursos}
				alt="Konfisio Cursos"
				loading="lazy"
				className="img-fluid"
				style={{ minWidth: '250px', height: '220px' }}
			/>
		</div>
	);
}

export default ImageCursos;
