import React from 'react';
import foto1 from '../../../assets/img/galeria/1.webp';
import foto2 from '../../../assets/img/galeria/2.webp';
import foto3 from '../../../assets/img/galeria/3.webp';
import foto4 from '../../../assets/img/galeria/4.webp';
import foto5 from '../../../assets/img/galeria/5.webp';
import foto6 from '../../../assets/img/galeria/6.webp';
import foto7 from '../../../assets/img/galeria/7.webp';
import foto8 from '../../../assets/img/galeria/8.webp';

export default function GaleriaDeFotos() {
	return (
		<section id="agenda" className="container my-5">
			<div className="row justify-content-center align-items-center">
				<hr className="hr-konfisio mb-4" />
				<h4 className="text-start p-2" style={{ color: 'var(--primary-theme-color)' }}>
					<i className="fa-solid fa-camera me-2" />
					Fotos Konfisio
				</h4>
			</div>
			<div className="row justify-content-center align-items-center">
				<div className="col-12 col-lg-3">
					<img src={foto1} alt="Foto 1" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
				<div className="col-12 col-lg-3">
					<img src={foto2} alt="Foto 2" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
				<div className="col-12 col-lg-3">
					<img src={foto3} alt="Foto 3" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
				<div className="col-6 col-lg-3">
					<img src={foto4} alt="Foto 4" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
				<div className="col-6 col-lg-3">
					<img src={foto5} alt="Foto 5" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
				<div className="col-12 col-lg-3">
					<img src={foto6} alt="Foto 6" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
				<div className="col-6 col-lg-3">
					<img src={foto7} alt="Foto 7" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
				<div className="col-6 col-lg-3">
					<img src={foto8} alt="Foto 8" className="img-fluid mx-auto d-block img-thumbnail my-2" />
				</div>
			</div>

		</section>
	);
}
