import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { DateTime } from 'luxon';
import Axios from '../../../api/axios';
import { URL_GET_ALL_SCHEDULE } from '../../../api/apiUrls';
import { getCurrentDate, formatDate } from '../../../utils/dates';
import { ISchedule } from '../../../interfaces';

export default function AgendaDeCursos() {
	const [schedule, setSchedule] = useState<ISchedule[]>([]);

	const [monthsSchedule, setMonthsSchedule] = useState<string[]>([]);

	const [scheduleError, setScheduleError] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getScheduledCourses = async () => {
			await Axios.get(URL_GET_ALL_SCHEDULE)
				.then((response) => {
					setSchedule(response.data);
					const months = (response.data).map((e: ISchedule) => DateTime.fromISO(e.dataInicial).setLocale('pt-br').toFormat('MMMM/yyyy'));
					setMonthsSchedule([...new Set<string>(months)]);
					setScheduleError(false);
				})
				.catch(() => setScheduleError(true));
			setIsLoading(false);
		};
		getScheduledCourses();
	}, []);

	const formatInitialFinalDate = (initiaLDate: string, finalDate: string) => {
		const formatInitialDate = DateTime.fromISO(initiaLDate, { zone: 'utc' }).toFormat('dd/MM/yy');
		const formatFinalDate = DateTime.fromISO(finalDate, { zone: 'utc' }).toFormat('dd/MM/yy');
		if (formatInitialDate === formatFinalDate) return formatInitialDate;
		return `${formatInitialDate} a ${formatFinalDate}`;
	};

	return (
		<section id="agenda" className="container my-5">
			<div className="row justify-content-center align-items-center">
				<h2 className="text-center rounded-3 p-3 shadow-sm" style={{ background: 'var(--primary-theme-color)', color: 'white' }}>
					<i className="fa-solid fa-calendar-day me-2" />
					Agenda de Cursos Presenciais
				</h2>
				<div className="col-11">
					<HashLink
						to="/matricula-online"
						type="button"
						className="btn btn-lg btn-light mx-auto d-block my-3 d-none"
					>
						Clique aqui para fazer a matrícula
					</HashLink>
					{isLoading && (
						<div className="text-center">
							<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
						</div>
					)}
					{scheduleError && (
						<div className="alert alert-danger text-center">
							Houve um erro ao recuperar a agenda, atualize a página ou tente novamente mais tarde.
						</div>
					)}
					{monthsSchedule.length > 0
						? (monthsSchedule.map((e) => {
							const month = e;
							const monthUpperCase = e[0].toUpperCase() + e.substring(1);
							return (
								<ul key={e} className="list-group list-group-flush mt-2 p-3">
									<h5 className="konfisio-color"><b>{monthUpperCase}</b></h5>
									{schedule.filter((date) => month === DateTime.fromISO(date.dataInicial)
										.setLocale('pt-br')
										.toFormat('MMMM/yyyy'))
										.map((element) => (
											formatDate(element.dataInicial, 'yyyy-MM-dd') >= getCurrentDate('USA').replaceAll('/', '-') && (
												<li
													key={element.index}
													className="list-group-item"
													style={{ color: '#3ca2a2', fontSize: '1.2rem' }}
												>
													<div className="row align-items-center justify-content-evenly">
														<div className="col-12 col-lg-12">
															<Link to={`/cursos/${element.idCurso}`} className="agendalink">
																<span className="mt-2 ">
																	{
																		`${formatInitialFinalDate(element.dataInicial, element.dataFinal)} `
																	}
																	<span style={{ color: '#0d6efd' }}>
																		{element.local && ` (${element.local}) `}
																	</span>
																	<span>
																		-
																		<b>
																			{' '}
																			{element.nome}
																		</b>
																	</span>
																</span>
															</Link>
														</div>
													</div>
												</li>
											)
										))}
									<span />
								</ul>
							);
						}))
						: !isLoading && !scheduleError && (<span>Nenhum curso agendado no momento</span>)}
				</div>
			</div>
		</section>
	);
}
