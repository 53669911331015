/* eslint-disable */
import ImageKit from 'imagekit-javascript';

export default function getIKClient() {
  const ikClient = new ImageKit({
    "sdkVersion": "react-1.1.1",
    "urlEndpoint": "https://ik.imagekit.io/xwugtefge",
  });
  return ikClient;
}

// {
//   "options": {
//       "sdkVersion": "react-1.1.1",
//       "publicKey": "",
//       "urlEndpoint": "https://ik.imagekit.io/xwugtefge",
//       "transformationPosition": "path"
//   }
// }