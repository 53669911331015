import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { toastTopFullWidth, PainelNavBar } from '../../../components';
import Axios from '../../../api/axios';
import AppContext from '../../../context/AppContext';
import { URL_GET_ALL_COURSES } from '../../../api/apiUrls';
import { ICourse } from '../../../interfaces';

export default function Course() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopFullWidth;

	const { currentUser } = useContext(AppContext);

	const [listOfCourses, setListOfCourses] = useState<ICourse[]>([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getAllCourses = async () => {
			await Axios.get(URL_GET_ALL_COURSES)
				.then((response) => {
					setListOfCourses(response.data);
					console.log(response.data);
				})
				.catch((error) => toastr.error(`${error.message} - Entre em contato com o Administrador`));
			setIsLoading(false);
		};
		getAllCourses();
	}, []);

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/course">
								<i className="fa-solid fa-list me-2" />
								Lista de Cursos
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row">
					<div className="col-12 mt-2">
						<Link to="/panel/course/add" className={`btn btn-primary ${currentUser.permissionLevel !== 0 && 'disabled'}`}>
							<i className="fa-solid fa-circle-plus me-2" />
							Cadastrar novo Curso
						</Link>
						{isLoading && (
							<div className="text-center">
								<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
							</div>
						)}
						<ul className="list-group list-group-flush mt-4">
							{listOfCourses.map((curso, index) => (
								<li key={curso.index} className={`list-group-item ${index % 2 === 0 ? '' : 'bg-light'}`}>
									<div className="row align-items-center justify-content-evenly">
										<div className="col-9 col-lg-9">
											<Link to={`/panel/course/${curso.idCurso}`}>
												<span className="mt-2">{curso.nome}</span>
											</Link>
										</div>
										<div className="col-3 col-lg-2 text-end">
											<Link to={`/panel/course/edit/${curso.idCurso}`} className="btn btn-md btn-secondary mx-2">
												<i className="fa-regular fa-pen-to-square me-md-2" />
												<span className="d-none d-sm-none d-md-inline">Editar</span>
											</Link>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</section>
		</>
	);
}
