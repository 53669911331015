import React from 'react';

export default function InfoPagamentos() {
	return (
		<div>
			<h4>Matrícula:</h4>
			<div className="p-2" style={{ background: '#ffff00' }}>
				<p className="mt-3">
					- Fica
					<b> isento </b>
					da taxa de R$120,00 de matrícula o aluno que efetuar
					o pagamento nas seguintes formas:
				</p>
				<p>
					- Valor total a vista pago no ato da matrícula via deposito,
					transferência ou pix.
				</p>
				<p>
					- Valor Parcelado: o aluno deve solicitar o link do pagamento via whatsapp
					(22) 999329536.
				</p>
			</div>
			<p className="mt-3" style={{ color: 'blue' }}>
				Cursos: Taxa de matrícula dos cursos R$120,00
				(valor da matrícula não é abatido do valor total do curso).
				O curso deve ser quitado com até 5 dias de antecedência da data
				de realização do mesmo.
			</p>
			<p style={{ color: 'blue' }}>
				Pós-Graduação: R$150,00 via depósito
				(Valor da matrícula não é abatido do valor total do curso).
			</p>

			<h5>
				<b>Está com dúvida? Entre em contato conosco. </b>
				(22) 999329536 / matriculas@konfisio.com
			</h5>
			<br />
			<i className="fas fa-money-check-alt" style={{ fontSize: '1.6em', color: '#000' }} />
			<br />
			<b>Dados Bancários para depósito:</b>
			<br />
			<b>PIX - Banco do Brasil</b>
			<br />
			04510329776
			<br/>
			Karla Fabiana Barcelos Teixeira
			<br />
			<br />
			<b>Banco do Brasil</b>
			{' '}
			<br />
			Agência: 3028-7 Conta Corrente: 28822-5
			<br />
			Karla Fabiana Barcelos Teixeira
			<br />
			<br />
			<b>Cartão:</b>
			{' '}
			Solicitar o link de pagamento pelo whatsapp
			<a
				href="https://api.whatsapp.com/send?phone=5522999329536&text=Ol%C3%A1%2C%20tenho%20interesse%20nos%20cursos%20da%20Konfisio"
				target="_blank"
				rel="noreferrer"
				className="ms-2"
			>
				<i className="fa-brands fa-whatsapp me-1" />
				22999329536
			</a>
		</div>
	);
}
