import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import Axios from '../../../api/axios';
import { URL_GET_ALL_PLATFORMS_EAD } from '../../../api/apiUrls';
import { PainelNavBar, toastTopFullWidth } from '../../../components';
import { IPlatform } from '../../../interfaces';

export default function PlatformsEAD() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopFullWidth;

	const [listOfPlatforms, setListOfPlatforms] = useState<IPlatform[]>([]);

	useEffect(() => {
		const getAllPlatformsEAD = async () => {
			await Axios.get(URL_GET_ALL_PLATFORMS_EAD)
				.then((response) => setListOfPlatforms(response.data))
				.catch((error) => toastr.error(`${error.message} - Entre em contato com o Administrador`));
		};
		getAllPlatformsEAD();
	}, []);

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/platforms-ead">
								<i className="fa-solid fa-desktop me-2" />
								Plataformas EAD
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row">
					<div className="col-12 mt-2">
						<Link to="/panel/platforms-ead/add" className="btn btn-primary disabled">
							<i className="fa-solid fa-circle-plus me-2" />
							Cadastrar nova Plataforma
						</Link>
						<ul className="list-group list-group-flush mt-4">
							{listOfPlatforms.map((plataforma, index) => (
								<li key={plataforma.index} className={`list-group-item ${index % 2 === 0 ? '' : 'bg-light'}`}>
									<div className="row align-items-center justify-content-evenly">
										<div className="col-9 col-lg-9">
											<Link to={`/panel/platforms-ead/edit/${plataforma.idPlataforma}`}>
												<span className="mt-2">{plataforma.nome}</span>
											</Link>
										</div>
										<div className="col-3 col-lg-2 text-end">
											<Link to={`/panel/platforms-ead/edit/${plataforma.idPlataforma}`} className="btn btn-md btn-secondary mx-2">
												<i className="fa-regular fa-pen-to-square me-md-2" />
												<span className="d-none d-sm-none d-md-inline">Editar</span>
											</Link>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</section>
		</>
	);
}
