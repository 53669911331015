import React from 'react';
import { Footer, MainNavbar, PosGraduacao } from '../components';

export default function Home() {
	return (
		<>
			<header className="mb-5">
				<MainNavbar />
			</header>
			<div className="mt-5">.</div>
			<PosGraduacao />
			<Footer />
		</>
	);
}
