import React from 'react';
import './sectionhero.css';
import logo from '../../../assets/img/logo-2021.webp';
import profile from '../../../assets/img/profile3.webp';

export default function SectionHero() {
	const ageKonfisio = () => {
		const date = new Date();
		const age = date.getFullYear() - 2000;
		return age;
	};

	return (
		<section id="hero">
			<div className="px-4 py-3 text-center">
				<img className="img-fluid d-block mx-auto my-5" src={logo} alt="Konfisio" width="400" height="400" />
				<div className="col-lg-6 mx-auto">
					<p className="lead mb-4">{`A Konfisio Cursos é um segmento de ensino coordenado pela Drª Karina Barcelos há ${ageKonfisio()} anos. Na prática profissional de sua clínica, a Konfisio, a mesma iniciou um processo de formação de profissionais que ao longo dos anos foi crescendo e hoje oferece Cursos Livres na área de estética, formação profissional, área de fisioterapia e Pós Graduação reconhecida pelo MEC.`}</p>
					<div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
						<a
							href="https://api.whatsapp.com/send?phone=5522999329536&text=Ol%C3%A1%2C%20tenho%20interesse%20nos%20cursos%20da%20Konfisio"
							target="_blank"
							rel="noreferrer"
							className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
						>
							<i className="fa-brands fa-whatsapp me-2" />
							Entrar em contato
						</a>
					</div>
				</div>
			</div>
			<div className="container px-4 my-2">
				<div className="row justify-content-center align-items-center py-3">
					<div className="col-10 col-lg-4 mx-3">
						<img src={profile} className="img-fluid mx-auto d-block img-profile my-3" alt="Dra. Karina Barcelos" width="350" height="500" loading="lazy" />
					</div>
					<div className="col-lg-7 p-3">

						<p>
							<strong>&quot;Para cima do medo, CORAGEM&quot;</strong>

						</p>
						<p>
							<strong>Dra. Karina Barcelos </strong>
							segue esse lema de vida e nessa visão
							ministra Cursos livres de formação, pós graduações, capacitando
							pessoas para construção de uma carreira sólida.
						</p>
						<span>{`São ${ageKonfisio()} anos de experiência como:`}</span>
						<ul>
							<li>Fisioterapeuta</li>
							<li>Nutricionista</li>
							<li>Mestre em Ciências das Religiões Aplicada a Estética Feminina</li>
							<li>Pós graduada em Acupuntura</li>
							<li>Pós graduada em Recursos Manuais</li>
							<li>Pós graduada em Fisioterapia Dermato-funcional</li>
							<li>Pós graduada em Dietoterapia Chinesa</li>
						</ul>
						<p>
							Na sua trajetória profissional desenvolve um trabalho sólido como
							facilitadora de conhecimento em cursos de formação, cursos
							livres e coordena pós graduações por grandes faculdades em
							Campos e todo Brasil.
						</p>
						{/* <p>
              <strong>Dra. Karina Barcelos </strong>
              é mestre em Ciências das Religiões abordando a Mulher e a Estética.
              Fisioterapeuta e Nutricionista.
            </p>
            <p>
              Pós graduada em Acupuntura, Recursos Terapêuticos Manuais e
              em Fisioterapia Dermato Funcional,
              Dietoterapia Chinesa Atualmente pós graduando em Nutrição Clínica e Esportiva.
            </p>
            <p>Proprietária e responsável técnico do Espaço de Saúde Konfisio.</p>
            <p>
              Há 15 anos coordena as Pós graduações em Acupuntura, Fisioterapia Dermato Funcional
              com Injetáveis, Recursos Terapêuticos Manuais, Dietoterapia e Fitoterapia Chinesa e
              Estética Avançada. Seu lema de trabalho sempre foi
              &quot;Para cima do medo, CORAGEM&quot;
              e nessa visão ministra
              Cursos livres de formação, capacitando pessoas para construção de uma carreira sólida.
            </p> */}
						<div className="d-grid gap-2 d-md-flex justify-content-md-start">
							<a href="https://instagram.com/konfisioka" target="_blank" rel="noreferrer" className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1">
								<i className="fa-brands fa-instagram me-2" />
								konfisioka
							</a>
							<a href="http://lattes.cnpq.br/0966239944268512" target="_blank" rel="noreferrer" className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1">Acesse Lattes</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
