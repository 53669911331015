import React from 'react';
import { HashLink } from 'react-router-hash-link';

export default function NotFound404() {
	return (
		<section
			className="container-fluid"
			style={{
				height: '100vh',
				maxHeight: '100vh',
			}}
		>
			<div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
				<div className="col-10 col-md-6 col-lg-4">
					<h3 className="my-2">
						Parece que você se perdeu.
						{' '}
						<br />
						{' '}
						Não há nada por aqui.
					</h3>
					<HashLink to="/" className="btn btn-primary my-3 mb-4">
						<i className="fa-solid fa-house me-2" />
						Clique aqui para voltar
					</HashLink>
					<h5 className="alert alert-danger">Erro 404</h5>
				</div>
			</div>
		</section>
	);
}
