import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './mainnavbar.css';
import favicon from '../../assets/img/logo-brand.png';
import LiMainNavbar from './LiMainNavbar';

export default function MainNavbar() {
	return (
		<nav className="navbar fixed-top navbar-expand-lg bg-light" aria-label="Navbar Konfisio">
			<div className="container">
				<Link to="/" className="navbar-brand"><img src={favicon} alt="Konfisio" width="48px" height="48px" /></Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsKonfisio" aria-controls="navbarsKonfisio" aria-expanded="false" aria-label="Toggle navigation">
					<span className="toggle-konfisio">
						Menu
						{' '}
						<span className="navbar-toggler-icon" />
					</span>
				</button>
				<div className="collapse navbar-collapse" id="navbarsKonfisio">
					<ul className="navbar-nav ul-konfisio">
						<LiMainNavbar href="/matricula-online" innerText="Matrícula Online" />
						<li className="nav-item">
							<HashLink to="/#agenda" className="nav-link">Cursos Agendados</HashLink>
						</li>
						<LiMainNavbar href="/info-pagamentos" innerText="Dados Pagamento" />
						<li className="nav-item">
							<HashLink to="/#posgraduacao" className="nav-link">Pós-graduação</HashLink>
						</li>
						<LiMainNavbar href="/cursos-online" innerText="Cursos Online" />
						<li className="nav-item">
							<a href="https://konfisio.com/ead" className="nav-link">Plataforma EAD</a>
						</li>
						<li className="nav-item">
							<a href="https://api.whatsapp.com/send?phone=5522999329536&text=Ol%C3%A1%2C%20tenho%20interesse%20nos%20cursos%20da%20Konfisio" className="nav-link">Contato</a>
						</li>
						<li className="nav-item">
							<a href="https://konfisio.com/clinica" className="nav-link">Clínica</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}
