import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from './AppContext';
import Axios from '../api/axios';
import { URL_GET_SYSTEM_BY_ID } from '../api/apiUrls';

export default function AppProvider({ children }) {
	const [systemIsActive, setSystemIsActive] = useState(true);

	const [globalLoading, setGlobalLoading] = useState(true);

	const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
		const getSystemById = async () => {
			await Axios.get(`${URL_GET_SYSTEM_BY_ID}/ae218ea0-36c7-11ed-b7ad-838a93f4d704`)
				.then((response) => {
					setSystemIsActive(response.data[0].isActive);
					setGlobalLoading(false);
				})
				.catch((error) => {
					console.log(error);
					setGlobalLoading(false);
				});
		};
		getSystemById();
	}, []);

	const contextValue = useMemo(() => ({
		systemIsActive,
		setSystemIsActive,
		globalLoading,
		isUserLoggedIn,
		setIsUserLoggedIn,
		currentUser,
		setCurrentUser,
	}));

	return (
		<AppContext.Provider value={contextValue}>
			{children}
		</AppContext.Provider>
	);
}

AppProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
