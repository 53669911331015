import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import './painelnavbar.css';
import favicon from '../../assets/img/logo-brand.png';
import LiPainelNavBar from './LiPainelNavbar';

export default function MainNavbar() {
	const { currentUser } = useContext(AppContext);

	const { setIsUserLoggedIn, setCurrentUser } = useContext(AppContext);

	const navigate = useNavigate();

	const logUserOut = () => {
		setCurrentUser(null);
		setIsUserLoggedIn(false);
		navigate('/panel/login');
	};
	return (
		<nav className="navbar fixed-top navbar-expand-lg bg-light" aria-label="Navbar Konfisio">
			<div className="container">
				<Link to="/panel" className="navbar-brand"><img src={favicon} alt="Konfisio" width="48px" height="48px" /></Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsKonfisio" aria-controls="navbarsKonfisio" aria-expanded="false" aria-label="Toggle navigation">
					<span className="text-light">
						<span className="navbar-toggler-icon" />
					</span>
				</button>
				<div className="collapse navbar-collapse row justify-content-between" id="navbarsKonfisio">
					<ul className="navbar-nav ul-konfisio-painel col-lg-7">
						<LiPainelNavBar href="/panel/schedule" innerText="Agenda" />
						<LiPainelNavBar href="/panel/course" innerText="Cursos" />
						<LiPainelNavBar href="/panel/graduation" innerText="Pós-graduação" />
					</ul>
					<div className="col-lg-5 d-flex align-items-center justify-content-end text-muted">
						<i className="fa-regular fa-circle-user fa-xl me-2" />
						{currentUser.email}
						<button
							type="button"
							className="btn btn-sm btn-outline-danger ms-3 logout-button"
							onClick={logUserOut}
						>
							<i className="fa-solid fa-right-from-bracket" />
						</button>
					</div>

				</div>
			</div>
		</nav>
	);
}
