import { DateTime } from 'luxon';

export const getCurrentDate = (locale = 'BR') => {
	const date = new Date();
	const year = date.getFullYear();
	const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
	const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
	if (locale === 'BR') return `${day}/${month}/${year}`;
	return `${year}/${month}/${day}`;
};

export const formatDate = (date: string, format = 'dd/MM/yyyy') => DateTime.fromISO(date)
	.setLocale('pt-br')
	.toFormat(format);
