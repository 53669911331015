import React, { useState, useRef, FormEvent, ChangeEvent } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { toastTopRight } from '../../../components/Toastr/index';
import Axios from '../../../api/axios';
import { URL_ADD_NEW_GRADUATION, URL_IMAGEKIT_AUTHENTICATION, BASE_URL } from '../../../api/apiUrls';
import PainelNavBar from '../../../components/PainelNavbar/PainelNavBar';
import IKUploadK from '../../../components/IKUpload/index';
// TODO: remover espaço em branco no início e final dos inputs
// TODO: automatizar o createdBy com a identificação do current user

export default function AddNewGraduation() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const INITIAL_STATE = {
		idGraduacao: uuidv1(),
		nome: '',
		descricao: '',
		local: '',
		publicoAlvo: '',
		cargaHoraria: '',
		coordenacao: '',
		corpoDocente: '',
		investimento: '',
		informacoesAdicionais: '',
		conteudo: '',
		imagem: '',
		createdBy: 'admin',
		modifiedBy: 'admin',
	};

	const [inputs, setInputs] = useState(INITIAL_STATE);

	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const [isLoading, setLoading] = useState(false);

	const childCompRef = useRef();

	const validateRequiredFormInputs = () => {
		const inputsToValidate = [inputs.nome, inputs.investimento, inputs.conteudo];
		setIsButtonDisabled(inputsToValidate.some((input) => input.length === 0));
	};

	const handleFormInputs = ({ target }: ChangeEvent<HTMLInputElement>) => {
		setInputs((prevState) => ({ ...prevState, [target.name]: target.value }));
		validateRequiredFormInputs();
	};

	const saveGraduationOnDataBase = async () => {
		await Axios.post(URL_ADD_NEW_GRADUATION, inputs)
			.then(() => {
				// eslint-disable-next-line
				// @ts-ignore
				childCompRef.current.uploadFile();
				toastr.success('Pós-graduação cadastrada com sucesso!');
				setInputs(INITIAL_STATE);
				setIsButtonDisabled(true);
				setLoading(false);
			})
			.catch(() => {
				toastr.error('Um erro aconteceu. Tente novamente.');
				setLoading(false);
			});
	};

	const setDadComponentStateImage = () => {
		setInputs((prevState) => ({ ...prevState, imagem: inputs.idGraduacao }));
	};

	const saveGraduation = async (event: FormEvent) => {
		setLoading(true);
		event.preventDefault();
		await saveGraduationOnDataBase();
	};

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/panel/graduation">
								<i className="fa-solid fa-graduation-cap me-2" />
								Pós-graduação
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/graduation/add">
								<i className="fa-solid fa-circle-plus me-2" />
								Cadastrar nova Pós-graduação
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<form onSubmit={saveGraduation} className="row justify-content-center">
					<div className="col-12 col-lg-6">
						<div className="row p-md-2">
							<label htmlFor="nome" className="form-label col-lg-12 my-2">
								<strong>Nome</strong>
								<span style={{ color: 'red' }}> *</span>
								<input
									name="nome"
									type="text"
									className="form-control"
									placeholder="Nome do curso"
									value={inputs.nome}
									onChange={handleFormInputs}
									disabled={isLoading}
									required
								/>
							</label>
							<label htmlFor="descricao" className="form-label col-lg-12 my-2">
								<strong>Descrição</strong>
								<textarea
									name="descricao"
									className="form-control"
									placeholder="Descrição do curso"
									value={inputs.descricao}
									style={{ resize: 'none' }}
									rows={4}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="local" className="form-label col-lg-12 my-2">
								<strong>Local</strong>
								<input
									name="local"
									type="text"
									className="form-control"
									placeholder="Local"
									value={inputs.local}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="publicoAlvo" className="form-label col-lg-12 my-2">
								<strong>Público Alvo</strong>
								<input
									name="publicoAlvo"
									type="text"
									className="form-control"
									placeholder="Qual é o público alvo?"
									value={inputs.publicoAlvo}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="cargaHoraria" className="form-label col-lg-12 my-2">
								<strong>Carga Horária</strong>
								<input
									name="cargaHoraria"
									type="text"
									className="form-control"
									placeholder="Carga Horária"
									value={inputs.cargaHoraria}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
							<div className="form-label col-lg-12 my-2">
								<strong>Imagem</strong>
								<IKUploadK
									// eslint-disable-next-line
									// @ts-ignore
									fileName={inputs.idGraduacao}
									tags={[inputs.nome]}
									useUniqueFileName={false}
									folder="/konfisio-site/graduations"
									publicKey="public_JdnEOcQ+bNlV9WYwU8XtZgSJj7Y="
									urlEndpoint="https://ik.imagekit.io/xwugtefge"
									authenticationEndpoint={`${BASE_URL}${URL_IMAGEKIT_AUTHENTICATION}`}
									onChangeFunction={[() => { }, () => { }]} // eslint-disable-line
									setDadComponentStateImage={setDadComponentStateImage}
									disabled={isLoading}
									ref={childCompRef}
								/>
							</div>
							<label htmlFor="investimento" className="form-label col-lg-12 my-2">
								<strong>Investimento</strong>
								<span style={{ color: 'red' }}> *</span>
								<textarea
									name="investimento"
									className="form-control"
									placeholder="Investimento"
									value={inputs.investimento}
									style={{ resize: 'none' }}
									rows={3}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
									required
								/>
							</label>
							<label htmlFor="coordenacao" className="form-label col-lg-126 my-2">
								<strong>Coordenação Geral</strong>
								<input
									name="coordenacao"
									type="text"
									className="form-control"
									placeholder="Coordenação geral"
									value={inputs.coordenacao}
									onChange={handleFormInputs}
									disabled={isLoading}
								/>
							</label>
						</div>
					</div>
					<div className="col-12 col-lg-6">
						<div className="row p-md-2">
							<label htmlFor="corpoDocente" className="form-label col-lg-12 my-2">
								<strong>Corpo Docente</strong>
								<textarea
									name="corpoDocente"
									className="form-control"
									placeholder="Corpo Docente"
									value={inputs.corpoDocente}
									style={{ resize: 'none' }}
									rows={7}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
								/>
							</label>
							<label htmlFor="conteudo" className="form-labe col-lg-12 my-2">
								<strong>Conteúdo</strong>
								<span style={{ color: 'red' }}> *</span>
								<textarea
									name="conteudo"
									className="form-control"
									placeholder="Conteúdo do curso"
									value={inputs.conteudo}
									style={{ resize: 'none', height: '298px' }}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
									required
								/>
							</label>
							<label htmlFor="informacoesAdicionais" className="form-label col-lg-12 my-2">
								<strong>Informações Adicionais</strong>
								<textarea
									name="informacoesAdicionais"
									className="form-control"
									placeholder="Informações que não se enquadram nos outros campos"
									value={inputs.informacoesAdicionais}
									style={{ resize: 'none', height: '144px' }}
									onChange={handleFormInputs as any} // eslint-disable-line
									disabled={isLoading}
								/>
							</label>
						</div>
					</div>
					<div className="col-12 col-lg-6 text-center">
						{isLoading
							? (
								<button type="submit" className="btn btn-md btn-primary col-12 col-lg-6 mx-auto mt-3" disabled>
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
									Salvando...
								</button>
							)
							: (
								<button
									type="submit"
									className="btn btn-md btn-primary col-12 col-lg-6 mx-auto mt-3"
									disabled={isButtonDisabled}
								>
									<i className="fa-regular fa-floppy-disk me-2" />
									Salvar Curso
								</button>
							)}
						<br />
						<span style={{ color: 'red', fontSize: '14px' }}>* Informações obrigatórias</span>
					</div>
				</form>
			</section>
		</>
	);
}
