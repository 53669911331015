import React from 'react';
import { Footer, MainNavbar } from '../components';
import ventosaterapia from '../assets/img/cursos-online/ventosaterapia.webp';
import auriculoacupuntura from '../assets/img/cursos-online/auriculoacupuntura.webp';
import combo6em1 from '../assets/img/cursos-online/combo6em1.webp';
import moxaterapia from '../assets/img/cursos-online/moxaterapia.webp';
import spiralTape from '../assets/img/cursos-online/spiral-tape.webp';
import massagemTerapeutica from '../assets/img/cursos-online/massagem-terapeutica-antiestresse.webp';
import reflexologiaPodal from '../assets/img/cursos-online/reflexologia-podal.webp';
import stiper from '../assets/img/cursos-online/stiper.webp';

export default function CursosOnline() {
	return (
		<>
			<header className="mb-5">
				<MainNavbar />
			</header>
			<section className="album bg-light">
				<div className="container">
					<div className="row">
						<h3 className="mt-5">Cursos Online</h3>
						<p>
							O aluno faz todo o curso online pelo smartphone,
							tablet ou computador através da plataforma digital da Hotmart.
						</p>
						<p>‣ Certificado ‣ Videoaulas ‣ Apostilas em pdf</p>
					</div>
					<div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-4 py-4">
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={ventosaterapia} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Ventosaterapia</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										Método baseado na Medicina Chinesa.
										Vem sendo praticado há milhares de anos nos tratamentos de doenças e dor.
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://pay.hotmart.com/M16798403P?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://pay.hotmart.com/M16798403P?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={auriculoacupuntura} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Aurículo Acupuntura</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										Método de grandes resultados físicos e emocionais que usa sementes,
										cristais e agulhas.
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://payment.hotmart.com/N18216392L?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://payment.hotmart.com/N18216392L?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={combo6em1} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Combo 6 em 1</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										Ventosaterapia, Spiral Tape, Massagem Relaxante, Stiper,
										Reflexologia Podal e Limpeza de Pele (Ebook).
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://pay.hotmart.com/M22791118B?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://pay.hotmart.com/M22791118B?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={moxaterapia} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Moxaterapia</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										Acupuntura térmica feita pela combustão das ervas Artemisia sinensis e vulgaris.
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://payment.hotmart.com/T34868400S?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://payment.hotmart.com/T34868400S?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={spiralTape} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Spiral Tape (Técnica do Esparadrapo)</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										Técnica de origem japonesa utilizada para
										tratar desordens osteomusculares.
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://pay.hotmart.com/L16621455W?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://pay.hotmart.com/L16621455W?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={massagemTerapeutica} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Massagem Terapêutica Antiestresse</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										Consiste na manipulação dos músculos em tensão do dorso (cabeça, costas e pés).
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://pay.hotmart.com/B17376291D?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://pay.hotmart.com/B17376291D?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={reflexologiaPodal} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Reflexologia Podal</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										Técnica que utiliza pressão em pontos mapeados
										que correspondem aos nossos órgãos e promove cura através de
										pressões controladas em cada área.
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://pay.hotmart.com/L17433421Y?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://pay.hotmart.com/L17433421Y?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col">
							<div className="card shadow-sm" style={{ minHeight: '480px' }}>
								<img src={stiper} className="bd-placeholder-img card-img-top" width="100%" height="272" alt="Cursos Presenciais" />
								<div className="card-body">
									<h5><b>Stiper</b></h5>
									<p className="card-text mt-4" style={{ minHeight: '72px' }}>
										São pastilhas que contém óxido de silício.
										Pode ser usado no lugar das agulhas para tratar diversas patologias.
									</p>
									<div className="d-flex align-items-center">
										<a
											href="https://payment.hotmart.com/V17793283F?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-bg btn-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Adquirir
										</a>
										<a
											href="https://payment.hotmart.com/V17793283F?checkoutMode=10&split=12&src=site"
											className="btn btn-md konfisio-color-outline btn-outline-secondary px-2 mx-1"
											target="_blank"
											rel="noreferrer"
										>
											Mais informações
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}
