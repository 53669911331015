import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import Axios from '../api/axios';
import { URL_GET_ALL_COURSES } from '../api/apiUrls';
import { Footer, MainNavbar, toastTopRight } from '../components';

interface ILisOfCourses {
	index: string;
	idCurso: string;
	nome: string;
}

export default function ListaDeCursos() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const [lisOfCourses, setLisOfCourses] = useState<ILisOfCourses[]>([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getListOfCourses = async () => {
			await Axios.get(URL_GET_ALL_COURSES)
				.then((response) => setLisOfCourses(response.data))
				.catch(() => {
					toastr.error('Um erro aconteceu. Tente novamente.');
				});
			setIsLoading(false);
		};
		getListOfCourses();
	}, []);

	return (
		<>
			<header className="mb-5">
				<MainNavbar />
			</header>
			<section className="container" style={{ marginTop: '100px' }}>
				<div className="row">
					<div className="col-12">
						<h3>Lista de Cursos</h3>
						{isLoading && (
							<div className="text-center">
								<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
							</div>
						)}
						<ul className="list-group list-group-flush mt-2">
							{lisOfCourses.map((course, index) => (
								<li key={course.index} className={`list-group-item ${index % 2 === 0 ? '' : 'bg-light'}`}>
									<Link to={`/cursos/${course.idCurso}`}>
										<p className="my-3">{course.nome}</p>
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}
