import React, { useContext, ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';

interface ProtectedRouteProps {
	component: ComponentType;
}

export default function ProtectedRoute({ component: Component }: ProtectedRouteProps) {
	const { isUserLoggedIn } = useContext(AppContext);

	if (!isUserLoggedIn) {
		return <Navigate to="/panel/login" />;
	}

	return <Component />;
}
