import React from 'react';

export default function MatriculaOnline() {
	return (
		<div className="alert alert-warning">
			<h5 className="text-center mb-3">ATENÇÃO ALUNO:</h5>
			<b>
				A ficha é individual por curso,
				caso queira matricular-se em mais de um curso favor preencher uma ficha por curso.
			</b>
			<br />
			<br />
			<p className="text-start" style={{ lineHeight: '30px' }}>
				<b>-</b>
				&nbsp;
				Após o envio da ficha é obrigatório efetuar o pagamento da matrícula
				do curso no mesmo dia ou sua ficha será invalidada.
				<br />
				<b>-</b>
				&nbsp;
				Fica isento de pagamento de matrícula o aluno que efetuar o pagamento integral
				do Curso a vista ou a prazo segundo termos na tabela.
				(a prazo está explicado como fazer).
				<br />
				<b>-</b>
				&nbsp;
				Matrículas realizadas um dia antes do Curso não terá isenção de matrícula.
				<br />
				<b>-</b>
				&nbsp;
				Apenas é isento da taxa de matricula de R$120,00 o aluno que efetuar o pagamento
				integral do curso à vista ou a prazo. Caso vc não efetue e preencha nova ficha
				para o mesmo curso, não haverá mais a possibilidade de isenção de matricula.
				<br />
				<b>-</b>
				&nbsp;
				Em caso de promoções veiculadas em redes sociais fica valendo as regras da
				mesma em caráter especial.
				<br />
				Obs.: Ao se esgotarem as vagas para cada curso, o mesmo será avisado na coluna de
				datas de cursos.
			</p>
		</div>
	);
}
