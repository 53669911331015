import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { PainelNavBar, toastTopRight } from '../../../components';
import Axios from '../../../api/axios';
import AppContext from '../../../context/AppContext';
import { URL_GET_ALL_GRADUATIONS, URL_EDIT_ACTIVE_GRADUATION } from '../../../api/apiUrls';
import { IGraduation } from '../../../interfaces';

export default function Graduation() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const { currentUser } = useContext(AppContext);

	const [listOfGraduations, setListOfGraduations] = useState<IGraduation[]>([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const getListOfGraduations = async () => {
			await Axios.get(URL_GET_ALL_GRADUATIONS)
				.then((response) => setListOfGraduations(response.data))
				.catch((error) => toastr.error(`${error.message} - Entre em contato com o Administrador`));
			setIsLoading(false);
		};
		getListOfGraduations();
	}, []);

	const changeGraduationActive = async (id: number) => {
		setIsLoading(true);
		const findGraduation = listOfGraduations.find((e: IGraduation) => e.index === id);
		const newGraduationActive = {
			...findGraduation,
			isActive: findGraduation?.isActive === 0 ? 1 : 0,
		};
		await Axios.put(URL_EDIT_ACTIVE_GRADUATION, {
			newGraduationActive,
		})
			.then(() => {
				toastr.success('Alterações salvas com sucesso!');
			})
			.catch((error) => {
				toastr.error(`Um erro aconteceu. Tente novamente.${error}`);
			});
		setIsLoading(false);
	};

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/graduation">
								<i className="fa-solid fa-graduation-cap me-2" />
								Pós-graduação
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row">
					<div className="col-12 mt-2">
						<Link to="/panel/graduation/add" className={`btn btn-primary ${currentUser.permissionLevel !== 0 && 'disabled'}`}>
							<i className="fa-solid fa-circle-plus me-2" />
							Cadastrar nova Pós-graduação
						</Link>
						{isLoading && (
							<div className="text-center">
								<div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status" />
							</div>
						)}
						<ul className="list-group list-group-flush mt-4">
							{listOfGraduations.map((graduation, index) => (
								<li key={graduation.index} className={`list-group-item ${index % 2 === 0 ? '' : 'bg-light'}`}>
									<div className="row align-items-center justify-content-evenly">
										<div className="col-7 col-lg-8">
											<Link to={`/panel/graduation/${graduation.idGraduacao}`}>
												<span className="mt-2">{graduation.nome}</span>
											</Link>
										</div>
										<div className="col-5 col-lg-3 text-end text-center">
											<Link to={`/panel/graduation/edit/${graduation.idGraduacao}`}>
												<button type="button" className="btn btn-md btn-secondary mx-2 my-2">
													<i className="fa-regular fa-pen-to-square me-md-2" />
													<span className="d-none d-sm-none d-md-inline">Editar</span>
												</button>
											</Link>
											{graduation.isActive
												? (
													<button
														type="button"
														className="btn btn-md btn-outline-danger mx-2 my-2"
														// style={{ minWidth: '159px' }}
														onClick={() => changeGraduationActive(graduation.index)}
														disabled={isLoading}
													>
														<i className="fa-regular fa-eye-slash me-md-2" />
														<span className="d-none d-sm-none d-md-inline">Ocultar no site</span>
													</button>
												)
												: (
													<button
														type="button"
														className="btn btn-md btn-outline-success mx-2 my-2"
														onClick={() => changeGraduationActive(graduation.index)}
														disabled={isLoading}
													>
														<i className="fa-regular fa-eye me-md-2" />
														<span className="d-none d-sm-none d-md-inline">&nbsp;Exibir no site &nbsp;</span>
													</button>
												)}
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</section>
		</>
	);
}
