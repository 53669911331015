import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { PainelNavBar, toastTopRight } from '../../components';
import Axios from '../../api/axios';
import { URL_GET_ALL_SYSTEMS, URL_EDIT_ACTIVE_SYSTEM } from '../../api/apiUrls';

interface IListOfSystems {
	index: string;
	isActive: number;
	nome: string;
	descricao: string;
}
export default function Config() {
	// eslint-disable-next-line
	// @ts-ignore
	toastr.options = toastTopRight;

	const [listOfSystems, setListOfSystems] = useState<IListOfSystems[]>([]);

	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		const getAllSystems = async () => {
			await Axios.get(URL_GET_ALL_SYSTEMS)
				.then((response) => setListOfSystems(response.data))
				.catch((error) => toastr.error(`${error.message} - Entre em contato com o Administrador`));
		};
		getAllSystems();
	}, [isLoading]);

	const changeSystemActive = async (id: string) => {
		setLoading(true);
		const findSystem = listOfSystems.find((e) => e.index === id);
		const newSystemActive = {
			...findSystem,
			isActive: findSystem?.isActive === 0 ? 1 : 0,
		};
		await Axios.put(URL_EDIT_ACTIVE_SYSTEM, {
			newSystemActive,
		})
			.then(() => {
				toastr.success('Alterações salvas com sucesso!');
				// window.location.reload(false);
				setLoading(false);
			})
			.catch((error) => {
				toastr.error(`Um erro aconteceu. Tente novamente.${error}`);
				setLoading(false);
			});
	};

	return (
		<>
			<header className="mb-4">
				<PainelNavBar />
				<nav className="container bg-light rounded-3 shadow-sm" aria-label="breadcrumb" style={{ marginTop: '100px' }}>
					<ol className="breadcrumb p-2">
						<span className="me-2 text-muted">Você está em:</span>
						<li className="breadcrumb-item">
							<Link to="/panel">
								<i className="fa-solid fa-gear me-2" />
								Painel
							</Link>
						</li>
						<li className="breadcrumb-item active">
							<Link to="/panel/admin">
								<i className="fa-solid fa-gears me-2" />
								Configurações
							</Link>
						</li>
					</ol>
				</nav>
			</header>
			<section className="container">
				<div className="row">
					<div className="col-12 mt-2">
						<ul className="list-group list-group-flush mt-4 border">
							{listOfSystems.map((system, index) => (
								<li key={system.index} className={`list-group-item ${index % 2 === 0 ? '' : 'bg-light'}`}>
									<div className="row align-items-center justify-content-start">
										<div className="col-7 col-lg-7">
											<p className="mt-2"><b>{system.nome}</b></p>
											<p className="mt-2">{system.descricao}</p>
										</div>
										<div className="col-5 col-lg-2 text-end">
											{system.isActive
												? (
													<button
														type="button"
														className="btn btn-md btn-outline-success mx-2 my-2"
														onClick={() => changeSystemActive(system.index)}
														disabled={isLoading}
													>
														<i className="fa-solid fa-toggle-on me-2" />
														Online
													</button>
												)
												: (
													<button
														type="button"
														className="btn btn-md btn-outline-danger mx-2 my-2"
														style={{ minWidth: '159px' }}
														onClick={() => changeSystemActive(system.index)}
														disabled={isLoading}
													>
														<i className="fa-solid fa-toggle-off me-2" />
														Manutenção
													</button>
												)}
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</section>
		</>
	);
}
